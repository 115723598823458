import SummaryEmail from '@/kit/controls/SummaryEmail'

export default (scope, params) => {
  return {
    name: 'messageEmail',
    controlComponent: SummaryEmail,
    controlProps: {
      scope,
      params,
      property: 'messageEmail'
    }
  }
}
