import SummarySms from '@/kit/controls/SummarySms'

export default (scope, params) => {
  return {
    name: 'messageSms',
    controlComponent: SummarySms,
    controlProps: {
      scope,
      params,
      property: 'messageSms'
    }
  }
}
