<template>
  <div>
    <!-- original: py-7 mb-12 -->
    <div v-if="activityTitle === 'Deep breathing'">
      <div
        v-if="sticky"
        class="page-header-space"
      >
      &nbsp;
      </div>
    </div>
    <div v-else>
      <div
        v-if="sticky"
        class="page-header-space py-4"
      >
      &nbsp;
      </div>
    </div>
    <div
      class="page-header-wrapper"
      :class="{
        sticky,
        'px-12': sticky,
        scrolled
      }"
    >
      <div class="page-header">
        <h1>
          <slot>Page title</slot>
        </h1>
        <header class="header-logo py-4">
          <router-link
            class="logo-link"
            :to="{ name: 'dashboard' }"
          >
            <img
              alt="Adhere.ly"
              class="logo-full"
              src="@/assets/logo.2.0.svg"
            >
            <img
              alt="Adhere.ly"
              class="logo-mobile"
              src="@/assets/logo-bird.2.0.svg"
            >
          </router-link>
          <div
            v-if="showInteractives"
            class="interactives"
          >
            <slot name="interactives">
            <!-- Special components can be placed here -->
            </slot>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Watching for scrolls is performance intensive, throttle it
// https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event

export default {
  name: 'PageHeader',
  props: {
    sticky: {
      type: Boolean,
      default: false
    },
    showInteractives: {
      type: Boolean,
      default: true
    },
    hasScrollBorder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrolled: false,
      scrollListener: null
    };
  },
  computed: {
    ...mapGetters({ 
      activityTitle: 'ux/activityTitle',
      reminderType: 'ux/reminderType',
      isPreviewMode: 'ux/isPreviewMode' 
    })
  },
  created() {
    if (this.hasScrollBorder) {
      let lastKnownScrollPosition = 0;
      let throttling = false;

      this.scrollListener = document.addEventListener('scroll', (e) => {
        lastKnownScrollPosition = window.scrollY;

        if (!throttling) {
          window.requestAnimationFrame(() => {
            this.scrolled = lastKnownScrollPosition > 0
            throttling = false;
          });

          throttling = true;
        }
      });
    }
  },
  beforeDestroy() {
    // Must stop listening to scrolls
    document.removeEventListener('scroll', this.scrollListener);
  }
}
</script>

<style lang="scss">

// Height of buttons/dropdowns/etc. in design
$header-height: 40px;

.header-logo {
  position: absolute;
  right: 1rem;
  top: 0;
  a {
    display: flex;
    justify-content: right;
    margin-bottom: 1rem;
  }
}

.logo-mobile {
  display: block;
}

.logo-full {
  display: none;
}

@media(min-width: 820px) {
  .logo-mobile {
    display: none;
  }

  .logo-full {
    display: block;
  }
}

.page-header-wrapper {
  background-color: #fff;
  padding: 1rem 0 0.5rem;

  &.sticky {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    
    // right: 0px;
    // left: var(--dashboard-width);
    z-index: 10;

    &.scrolled {
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .page-header {
    font-family: var(--font-family-primary);
    display: flex;
    align-items: center;
    // 48px is horizontal margins that need to be accounted for
    max-width: calc(var(--content-width) - 48px * 2);
    margin: 0.5rem 0;
    @media(min-width: 768px) {
      margin: 0;
    }

    &-space {
      // This is just supposed to take up space when the header is sticky
      line-height: $header-height;
    }
  
    h1 {
      display: flex;
      align-items: center;
      flex: auto;
      font-weight: 600; // 500
      font-size: 18px;
      line-height: 1.25;
      margin-left: 1rem;
      max-width: calc(100% - 2rem);
      min-height: 2rem;
      // padding-top: 1rem;

      @media(min-width: 768px) {
        font-size: 24px;
        line-height: $header-height;
        padding-top: 0;
      }
    }
  
    .interactives {
      display: flex;
      margin-left: auto;
  
      .v-btn {
        height: $header-height;
        line-height: $header-height;
        box-sizing: border-box;
        padding: 0px 16px;
        text-transform: none;
  
        box-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.1) !important;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        
        &:not(&.cta) {
          background: #FFFFFF;
          color: #615C5C;
          border: 1px solid #E0E0E0 !important;
        }
      }
    }
  }

}
</style>