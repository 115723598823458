import ControlRelaxation from '@/kit/controls/Relaxation'

export default (scope, params) => {
  return {
    name: 'relaxation',
    controlComponent: ControlRelaxation,
    controlProps: {
      scope,
      params,
      property: 'relaxation',
      background: 'secondary'
    }
  }
}
