<template>
  <svg
    width="182"
    height="182"
    viewBox="0 0 182 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="91"
      cy="91"
      r="90.5"
      stroke="#FF9175"
      stroke-opacity="0.3"
    />
    <circle
      cx="91.0008"
      cy="91.0008"
      r="66.3153"
      stroke="#FF9175"
      stroke-opacity="0.5"
      stroke-width="3"
    />
    <circle
      cx="91.5788"
      cy="91.5788"
      r="53.3248"
      fill="#FF9175"
    />
    <path
      d="M108.969 90.9994L82.0165 106.56L82.0165 75.4385L108.969 90.9994Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconPlayFilled',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
