<template>
  <v-form
    v-model="valid"
    @submit.prevent="next"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="!showPicker ? title : pickerTitle"
        />
      </v-col>
      <v-col :class="['col-12 pt-0 px-0', { 'pb-0': showPicker }]">
        <div
          v-if="!showPicker"
          class="list d-flex flex-wrap justify-center"
        >
          <div
            v-for="(item, index) in items"
            :key="index"
            class="choice-item-wrap"
          >
            <v-card
              :class="[
                'choice-item text-center',
                { selected: isSelected(item), disabled: limited() }
              ]"
              @click="click(item)"
            >
              <div class="choice-item-title">
                {{ formatTimeLocal(item) }}
              </div>
            </v-card>

            <div
              v-if="
                index === items.length - 1 &&
                  (isSelected(item) ? true : !limited())
              "
              class="choice-item-action-button"
            >
              <v-btn
                width="24"
                height="24"
                x-small
                fab
                @click.stop="togglePicker"
              >
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <custom-time-piker
          v-else
          :time="customTime"
          @input="pickerChange"
          @cancel="showPicker = false"
        />
      </v-col>

      <actions
        v-if="!showPicker"
        :show="!!values.length"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations } from 'vuex'
import { mdiPencil } from '@mdi/js'
import moment from '@/plugins/moment'
import Actions from '@/kit/components/Actions'
import CustomTimePiker from '@/components/CustomTimePiker'
import { times, hasTime } from '@/kit/helpers/datetime'

const DEFAULT_TIMES_LIMIT = 3

export default {
  components: { CustomTimePiker, Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    pickerTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: times(),
      mdiPencil,
      showPicker: false,
      valid: false,
      values: [],
      picker: null
    }
  },
  computed: {
    presetLastTime() {
      const presetTimes = times()

      return presetTimes[presetTimes.length - 1]
    },
    customTime() {
      return this.values.find(
        item => !hasTime(this.formatTimeUtcToLocal(item, 'HH:mm'))
      )
    }
  },
  beforeMount() {
    this.values = (this.scope[this.property] || []).slice()

    if (this.customTime) {
      this.updateLastItem(this.formatTimeUtcToLocal(this.customTime, 'HH:mm'))
    }
  },
  methods: {
    ...mapMutations({
      updateNotificationType: 'schedules/updateNotificationType'
    }),
    pickerChange(value) {
      this.values = this.values.filter(item =>
        hasTime(this.formatTimeUtcToLocal(item, 'HH:mm'))
      )

      const valueUTC = this.formatTimeUTC(value, 'HH:mm')

      if (!this.limited()) {
        this.values.push(valueUTC)
      }
      if (!hasTime(value)) {
        this.updateLastItem(value)
      } else {
        this.values.push(valueUTC)
      }

      this.values = this.values.filter(
        item => this.formatTimeUtcToLocal(item, 'HH:mm') !== this.presetLastTime
      )
      this.values = [...new Set(this.values)]

      this.showPicker = false
      this.picker = null
    },
    togglePicker() {
      this.showPicker = !this.showPicker
    },
    resetCustomTime() {
      this.values = this.values.filter(item =>
        hasTime(this.formatTimeUtcToLocal(item, 'HH:mm'))
      )

      this.updateLastItem(this.presetLastTime)
    },
    click(value) {
      const valueUTC = this.formatTimeUTC(value, 'HH:mm')
      if (this.values.find(item => item === valueUTC)) {
        this.values = this.values.filter(item => item !== valueUTC)

        if (!hasTime(value)) {
          this.updateLastItem(this.presetLastTime)
        }
      } else {
        if (!this.limited()) {
          this.values.push(valueUTC)
        }
      }
    },
    limited() {
      return (
        this.values.length >=
        (this.params.reminder.timesLimit || DEFAULT_TIMES_LIMIT)
      )
    },
    updateLastItem(value) {
      this.items[this.items.length - 1] = value
    },
    isSelected(value) {
      const valueUTC = this.formatTimeUTC(value, 'HH:mm')

      return !!this.values.find(item => item === valueUTC)
    },
    async next() {
      await this.$emit('track', {
        event: 'adhere.ly:time:selected',
        value: this.value
      })

      await this.$emit('go-to-next-step', {
        [this.property]: this.values.sort(
          (a, b) => moment(a, 'HH:mm') - moment(b, 'HH:mm')
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  max-width: 500px;
  margin: 0 auto;
  .choice-item {
    position: relative;
    width: 136px;
    margin: 7px;

    @media (max-width: 599px) {
      width: 150px;
    }

    @media (max-width: 480px) {
      width: 130px;
      padding: 0 15px;
    }
  }
}
</style>
