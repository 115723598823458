<template>
  <v-select
    :value="selectedFilter"
    :attach="true"
    :menu-props="{
      contentClass: 'select-generic-menu',
      left: true,
      offsetY: true
    }"
    class="v-select-generic select-filter"
    :items="items"
    dense
    hide-details
    @change="selectFilter"
  />
</template>

<script>
import Cookies from 'js-cookie'
import { mapActions } from 'vuex'

export default {
  props: ['value'],
  data() {
    return {
      items: [{
        text: 'All exercises',
        value: null
      }, {
        text: 'Adult exercises',
        value: 'adult'
      }, {
        text: 'Youth exercises',
        value: 'child'
      }, {
        text: 'Caregiver exercises',
        value: 'caregiver'
      }],
      selectedFilter: 'any'
    }
  },
  created() {
    // Select the internal "all" value by default
    if (!this.value || !this.value?.value) {
      // Load saved filter first
      const cookie = Cookies.get('exercise-filter')
      if (cookie) {
        if (cookie === 'null') {
          this.selectFilter(this.items[0].value)
        } else {
          this.selectFilter(cookie)
        }
        return
      }
      this.selectFilter(this.items[0].value)
    }
  },
  methods: {
    // ...mapActions('schedules', ['setFilter']),
    selectFilter(value) {
      this.selectedFilter = value

      this.$emit('input', this.selectedFilter)
      Cookies.set('exercise-filter', value, { expires: 365 })
      // this.setFilter(value)
    }
  }
}
</script>
<style scoped>
.select-filter {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-top: 0;
  max-height: 2rem;
  max-width: 14rem;
  padding: 0 12px;
}

>>>.v-input__icon {
  margin-top: 5px;
}

>>>.v-select__slot {
  font-size: 14px;
}
</style>
