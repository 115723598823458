import ControlVideo from '@/kit/controls/Video'

export default (scope, params) => {
  return {
    name: 'video',
    controlComponent: ControlVideo,
    width: 980,
    controlProps: {
      scope,
      params,
      background: params.previewMode ? 'secondary' : undefined,
      property: 'video'
    }
  }
}
