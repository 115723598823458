<template>
  <v-overlay
    absolute
    :color="color"
    :opacity="opacity"
    z-index="7"
  >
    <v-progress-circular
      color="primary"
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    opacity: {
      type: Number,
      default: 0.5
    }
  }
}
</script>
