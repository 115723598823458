<template>
  <v-row>
    <v-col
      cols="12"
      class="text-center"
    >
      <h1 class="h1">
        Demo complete!
      </h1>
    </v-col>
    <v-col
      cols="12"
      class="text-center"
    >
      <!-- Passed in via params -->
      <p>{{ params.completionText }}</p>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'DemoComplete',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // loaded: false,
      // success: false
    };
  },
}
</script>

<style lang="scss" scoped>
.scheduled {
  @media (max-width: 599px) {
    margin-top: 40px;
  }
  .h1 {
    margin-top: 20px;
    font-size: var(--workflow-scheduled-h1-font-size);
    font-weight: var(--workflow-scheduled-h1-font-weight);
    color: var(--v-primary-base);
  }
}
</style>
