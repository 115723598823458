<template>
  <section>
    <page-header
      :show-interactives="false"
      sticky
    >
      <!-- <title-with-client :title="title" /> -->
      <span class="practice-title">
        {{ slug.charAt(0).toUpperCase() + slug.slice(1) }}
      </span>
      <select-filter
        v-if="slug !== 'assess'"
        v-model="selectedFilter"
        class="audience-filter"
      />
      <text-filter
        v-model="textFieldFilter"
        :parent-page="slug"
        class="text-filter"
        @search-updated="updateSearch"
      />
    </page-header>
    
    <div
      v-if="item"
      :key="slug"
      class="practice"
    >
      <div v-if="filteredReminders.length !== 0">
        <section
          v-for="(reminder, index) in filteredReminders"
          :key="reminder.id"
        >
          <v-list one-line>
            <v-list-group value="true">
              <template
                v-slot:activator
              >
                <v-img
                  class="category-icon"
                  :src="categoryIconMap[reminder.title]"
                  :alt="reminder.title"
                />
              
                <v-list-item-content>
                  <span class="category-title">{{ reminder.title }}</span>
                </v-list-item-content>
              </template>
              <div class="reminder-list">
                <!-- TODO: Use v-hover on list items and conditional icon colors -->
                <v-list-item
                  v-for="(item, i) in reminder.items"
                  :key="item.title + i"
                  :class="{'reminder' : i < reminder.items.length - 1}"
                  link
                >
                  <v-list-item-content
                    class="reminder-content"
                    @click="reminderClickHandler(item)"
                  >
                    <v-list-item-title class="reminder-title">
                      <v-icon
                        v-if="item.type === 'chat'"
                        class="reminder-icon"
                        :style="'--hover-color: ' + colorMap[reminder.title]"
                      >
                        $chat
                      </v-icon>
                      <v-icon
                        v-if="item.type === 'interview'"
                        class="reminder-icon"
                        :style="'--hover-color: ' + colorMap[reminder.title]"
                      >
                        $interview
                      </v-icon>
                      <v-icon
                        v-if="item.type === 'video'"
                        class="reminder-icon"
                        :style="'--hover-color: ' + colorMap[reminder.title]"
                      >
                        $video
                      </v-icon>
                      <v-icon
                        v-if="item.type === 'game'"
                        class="reminder-icon"
                        :style="'--hover-color: ' + colorMap[reminder.title]"
                      >
                        $game
                      </v-icon>
                      <v-icon
                        v-if="item.type === 'journal'"
                        class="reminder-icon"
                        :style="'--hover-color: ' + colorMap[reminder.title]"
                      >
                        $journal
                      </v-icon>
                      <v-list-item-title class="text-wrap">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="reminder-description text-wrap"
                    >
                      {{ item.text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      :ripple="false"
                      class="view-button"
                      elevation="0"
                      @click="reminderClickHandler(item)"
                    >
                      View
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list-group>
          </v-list>
        </section>
      </div>
      <div 
        v-else
        class="empty-state"
      >
        No results match your search. Try again.
      </div>

      <base-modal
        v-if="dialog && selectedReminder"
        :reminder="selectedReminder"
        :dialog="dialog"
        :fullscreen="selectedReminder.isDemo"
        @modal-close="dialogClose"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import BaseModal from '@/components/BaseModal'
import PageHeader from '@/components/dashboard/PageHeader'
import reminders from '@/kit/dictionaries/reminders'
// import TitleWithClient from '@/views/dashboard/TitleWithClient'
import SelectFilter from '@/components/SelectFilter'
import TextFilter from '@/components/TextFilter'
import { mdiContactlessPaymentCircleOutline } from '@mdi/js'
// import remind from '@/kit/dictionaries/reminders/remind'

 const categoryColors = {
    Green: '#93cb4b',
    Yellow: '#ffb933',
    Blue: '#21c5dc',
    Teal: '#19cab4',
    Purple: '#b85efe',
    Red: '#ff897d',
  }

export default {
  name: 'Practice',
  components: {
    BaseModal,
    PageHeader,
    // TitleWithClient,
    SelectFilter,
    TextFilter
  },
  data: () => ({
    slug: null,
    item: null,
    dialog: false,
    selectedReminder: null,
    selectedFilter: null,
    textFieldFilter: '',
    remindersBodyHelperOffset: {},
    categoryIconMap: { // Map the category title to the svg asset
      'Custom reminder': require('@/assets/img/practice/custom-reminder.svg'),
      'Positive affirmations': require('@/assets/img/practice/positive-message.svg'),
      'Self-monitoring': require('@/assets/img/practice/self-monitoring.svg'),
      'Relaxation': require('@/assets/img/practice/relaxation.svg'),
      'Affect and emotion': require('@/assets/img/practice/affect-and-emotion.svg'),
      'Cognitive': require('@/assets/img/practice/cognitive-coping.svg'),
      'Exposure': require('@/assets/img/practice/exposure.svg'),
      'Parent-child activities': require('@/assets/img/practice/parent-child-activities.svg'),
      'Enhancing safety': require('@/assets/img/practice/enhancing-safety.svg'),
      'Parenting exercises': require('@/assets/img/practice/parenting-exercises.svg'),
      'Parenting videos': require('@/assets/img/practice/parenting-videos.svg'),
      'Social skills': require('@/assets/img/practice/social-skills.svg'),
      'Journals': require('@/assets/img/practice/journal.svg'),
      'Activity scheduling': require('@/assets/img/practice/activity-scheduling.svg'),
      'Sleep': require('@/assets/img/practice/sleep.svg'),
      'Anxiety': require('@/assets/img/practice/anxiety.svg'),
      'Depression': require('@/assets/img/practice/depression.svg'),
      'PTSD': require('@/assets/img/practice/ptsd.svg'),
      'Treatment Satisfaction': require('@/assets/img/practice/treatment-satisfaction.svg'),
      'Therapeutic Alliance': require('@/assets/img/practice/therapeutic-alliance.svg'),
      'Disruptive Behavior': require('@/assets/img/practice/disruptive-behavior.svg'),
      'Overall Mental Health': require('@/assets/img/practice/overall-mental-health.svg')
    },
    colorMap: { // Map the category title to the color for its icons 
      'Custom reminder': categoryColors['Green'],
      'Positive affirmations': categoryColors['Yellow'],
      'Self-monitoring': categoryColors['Blue'],
      'Relaxation': categoryColors['Teal'],
      'Affect and emotion': categoryColors['Purple'],
      'Cognitive': categoryColors['Blue'],
      'Exposure': categoryColors['Red'],
      'Parent-child activities': categoryColors['Red'],
      'Enhancing safety': categoryColors['Yellow'],
      'Parenting exercises': categoryColors['Teal'],
      'Parenting videos': categoryColors['Red'],
      'Social skills': categoryColors['Yellow'],
      'Journals': categoryColors['Yellow'],
      'Activity scheduling': categoryColors['Teal'],
      'Sleep': categoryColors['Blue'],
      'Anxiety': categoryColors['Purple'],
      'Depression': categoryColors['Blue'],
      'PTSD': categoryColors['Yellow'],
      'Treatment Satisfaction': categoryColors['Teal'],
      'Therapeutic Alliance': categoryColors['Blue'],
      'Disruptive Behavior': categoryColors['Purple'],
      'Overall Mental Health': categoryColors['Yellow']
    }
  }),
  computed: {
    ...mapGetters('clients', ['client', 'activity']),
    ...mapGetters('schedules', ['filter']),
    title() {
      // return format(this.item.title, this.client)
      const item = reminders.filter(item => item.slug === this.slug)
      return item[0]?.title ? item[0].title : 'Please select activity'
    },
    filteredReminders() {
      // Technically this component also handles Practice and Assess types too
      // Handle "any" case (any's value is typeof null)

      // sorting all categories and exercises to alphabetic order
      const clonedItems = cloneDeep(this.item.reminders)
      const sortedReminderTitles = clonedItems.sort((a, b) => {
        return this.compareStrings(a.title, b.title)
      })
      let sortedReminders = []
      sortedReminderTitles.forEach(reminder => {
        const sortedItems = reminder.items.sort((a, b) => {
          return this.compareStrings(a.title, b.title)
        })
        reminder.items = sortedItems
        sortedReminders.push(reminder)
      })

      // return if not filtering results, i.e. child activities, parent, etc.
      if (this.selectedFilter === null && this.textFieldFilter === '') {
        return sortedReminders
      }

      // Filter items (exercises) out of each reminder that don't belong in the filter
      const reminders = sortedReminders.reduce((accum, reminder) => {
        const filteredItems = reminder.items.filter(i => {
          const filterText = this.textFieldFilter.toLowerCase().trim()
          const titleOrCategoryMatches = 
            i.title.toLowerCase().includes(filterText) ||
            i.category.toLowerCase().includes(filterText)
          const audienceMatches = 
            !this.selectedFilter ||
            i.audience?.includes(this.selectedFilter)
          return titleOrCategoryMatches && audienceMatches
        })

        if (filteredItems.length > 0) {
          accum.push({ ...reminder, items: filteredItems })
        }

        return accum
      }, [])
      return reminders
    }
  },
  watch: {
    $route() {
      this.slug = this.$route.params.slug

      this.getItem()
    },
    'client.id': function() {
      if (!this.client.id) {
        this.$router.push({ name: 'patientSelect' })
      }
    }
  },
  created() {
    this.segmentTrack({ eventName: 'Practice View' })
    this.slug = this.$route.params.slug
  },
  mounted() {
    this.getItem()
  },
  // beforeDestroy() {
  //   this.resetUXState()
  // },
  methods: {
    ...mapActions({
      setActivity: 'clients/setActivity',
      setActivityUX: 'ux/setActivityUX',
      // resetUXState: 'ux/resetUXState'
    }),
    ...mapActions('analytics', ['segmentTrack']),
    updateSearch(value) {
      this.textFieldFilter = value
    },
    compareStrings(a, b) {
      return (a < b) ? -1 : (a > b) ? 1 : 0
    },
    getImageUrl(data) {
      console.log(data)
      return require('@/assets/img/' + data)
    },
    getItem() {
      [this.item] = reminders.filter(item => item.slug === this.slug)
    },
    reminderClickHandler(item) {
      let selectedReminder = {
        ...item,
        reminderType: this.slug,
        title: item.title,
        subtitle: item.text,
        notificationConfig: item.notificationConfig,
        uid: item.uid,
        workflow: item.workflow,
        exercise: item.exercise,
        audience: this.filter,
        id: item.id
      }
      this.setActivity(selectedReminder).then(() => {
        switch(this.activity.reminderType) {
          case 'assess':
            this.$router.push({name: 'activityShow', params: { slug: 'assess' }})
            break
          case 'practice':
            this.$router.push({name: 'activityShow', params: { slug: 'practice' }})
            break
          case 'remind':
            this.$router.push({name: 'activityShow', params: { slug: 'remind' }})
            break
          default:
            console.error('ERROR: reminderType not set')
        }

      })
      this.setActivityUX(selectedReminder)
    }
  }
}
</script>

<style scoped>

  .practice-title {
    display: flex;
    flex-shrink: 1;
    flex-basis: auto;
    margin-right: 20px;
  }

  .audience-filter {
    margin-right: 1rem;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
  }

  .text-filter {
    position: relative;
    top: 0;
  }

  .empty-state {
    height: 100%;
    font-family: var(--font-family-primary);
    font-size: 14px;
    color: #27201F;
    padding: 0 12px;
  }

  .reminder-list {
    border: 1px solid #e0e0e0; 
    border-radius: 8px;
    margin-top: 16px;
  }

  .reminder {
    border-bottom: 1px solid #e0e0e0;
  }

  >>>.v-select__selections {
    line-height: 1.5;
  }

  .v-list-group__items div {
    min-height: 0;
  }

  .category-icon {
    margin-left: -16px;
    margin-right: 8px;
    max-width: 40px;
  }
  
  /* Grid container: Reminder content */
  .reminder-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
  }

  /* Column 1: Reminder icon */
  .reminder-icon {
    width: 16px;
    max-width: 16px;
    margin-right: 8px;
  }
  
  .reminder-content:hover > .reminder-title > .reminder-icon {
    color: var(--hover-color);
  }

  /* Column 2: Reminder title */
  .reminder-title {
    display: flex;
    align-self: baseline;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    flex-basis: 30%;
  }

  /* Column 3: Reminder description */
  .reminder-description {
    align-self: left;
    display: flex;
    flex-basis: 70%;
    text-align: left;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  /* Column 4: View button */
  .view-button {
    display: none;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    height: 24px !important;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #27201F;
    background-color: #FFFFFF !important;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .v-list-item {
    background-color: #FCFCFC;
  }
  
  .v-list-item:first-child {
    border-radius: 8px 8px 0 0;
  }

  .v-list-item:last-child {
    border-radius: 0 0 8px 8px;
  }

  .v-list-item:only-child {
    border-radius: 8px 8px 8px 8px;
  }

  .v-list-item:hover {
    background-color: #F5F5F5;
  }

  .v-list-item:hover .view-button {
    display: flex;
  }

  .view-button::before {
    background-color: #ffffff;
  }


  @media (max-width: 599px) {
    .reminders-header {
      margin-bottom: 24px;
    }
  }

  .category-title {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #27201F;
  }

  .v-list-group__header__icon {
    display: none;
  }

  .reminders-body {
    max-width: 928px;
    margin: 0 auto;
  }

  @media (max-width: 1260px) {
    .reminders-body {
      max-width: 696px;
    }
  }
  @media (max-width: 1035px) {
    .reminders-body {
      max-width: 470px;
    }
  }

  @media (max-width: 730px) {
    .reminders-body {
      max-width: 465px;
    }
  }
</style>
