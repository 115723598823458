var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"youtube-container"},[(!_vm.videoLoaded)?_c('overlay',{attrs:{"color":"white"}}):_vm._e(),_c('div',{class:[
      'youtube-wrap',
      {
        mdOnly: _vm.$vuetify.breakpoint.mdOnly,
        smAndDown: _vm.$vuetify.breakpoint.smAndDown
      }
    ]},[_c('youtube',{attrs:{"video-id":_vm.videoId,"player-vars":_vm.playerVars,"resize":"","fit-parent":""},on:{"ready":_vm.ready,"playing":_vm.playing,"paused":_vm.stopped,"ended":_vm.stopped}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }