import ControlRelaxation2 from '@/kit/controls/Relaxation2'

export default (scope, params) => {
  return {
    name: 'relaxation2',
    controlComponent: ControlRelaxation2,
    controlProps: {
      scope,
      params,
      property: 'relaxation2',
      background: 'secondary'
    }
  }
}
