<template>
  <v-form
    ref="form"
    v-model="valid"
    :autocomplete="autocomplete ? 'on' : 'off'"
    @submit.prevent="next"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>
      <v-col cols="12">
        <form-autocomplete-name
          v-model="value"
          item-text="name"
          item-value="id"
          :placeholder="placeholder"
          height="var(--input-height)"
          :validate-on-blur="!showErrorMessage"
          :rules="rules"
          return-object
          :find-function="findClients"
          @search="search = $event"
          @input="autocompleteInput"
        />
      </v-col>

      <actions
        valid
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import FormAutocompleteName from '@/components/form/FormAutocompleteName'
import Actions from '@/kit/components/Actions'
import channels from '@/kit/dictionaries/channels'

export default {
  name: 'ControlAutocomplete',
  components: { FormAutocompleteName, Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    },
    track: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    autofocus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      valid: true,
      showErrorMessage: false,
      value: {},
      search: '',
      channels: channels()
    }
  },
  watch: {
    search(newValue) {
      if (this.value[this.property] !== newValue) {
        if (undefined !== this.value.id) {
          delete this.value.id
        }
        this.value[this.property] = newValue
      }
    }
  },
  mounted() {
    let id = this.scope.clientId
    if (
      (this.property === 'name' && undefined !== this.scope.id) ||
      this.scope.id
    ) {
      id = this.scope.id
    }

    this.value = this.scope[this.property]
      ? {
          id: id,
          [this.property]: this.scope[this.property]
        }
      : {}
  },
  methods: {
    ...mapActions('clients', ['findClients', 'setClientNew']),
    autocompleteInput(data) {
      if (data) {
        this.value = data
        this.setClientNew(this.value)

        if (this.scope.caregiver) {
          this.value['email'] = data['caregiverEmail'] || data['email']
          this.value['phone'] = data['caregiverPhone'] || data['phone']
          this.value['name'] = data['caregiverName'] || data['name']
          this.value['caregiverName'] = data['caregiverName'] || 'caregiver'
        }
      }
    },
    async next() {
      const channel = this.value.channel
      if ('name' === this.property && undefined === this.value.id) {
        this.value.id = 0
      }

      if (channel) {
        this.channels.forEach(item => {
          if (item.key === channel) {
            this.value.channel = item
            this.value.channelName = item.title.toLowerCase()
          }
        })
      }

      await this.$emit('track', {
        event: `adhere.ly:${this.property}:filled`,
        value: this.track ? this.value[this.property] : undefined
      })

      this.showErrorMessage = true

      if (undefined !== this.$refs.form && this.$refs.form.validate()) {
        this.$emit('go-to-next-step', this.value)
        this.value = {}
      }
    }
  }
}
</script>
