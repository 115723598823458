<template>
  <v-form
    class="relaxation"
    @submit.prevent="next"
  >
    <relaxation-balloon
      :duration="params.duration"
      @stats="updateSession"
    />
    
    <actions
      v-if="showPrevButton"
      :wrapper-class="align"
      :label="params.nextBtnLabel"
      :icon="params.nextBtnLabel ? null : undefined"
    />
  </v-form>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import format from 'string-template'
import { mapActions } from 'vuex'
import Actions from '@/kit/components/Actions'
import RelaxationBalloon from '@/kit/components/relaxation/RelaxationBalloon'

export default {
  name: 'Relaxation',
  components: { Actions, RelaxationBalloon },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    showPrevButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      notificationId: null,
      mdiPlus
    }
  },
  computed: {
    align() {
      return this.$vuetify.breakpoint.name === 'xs'
        ? 'justify-center'
        : 'justify-end'
    }
  },
  methods: {
    ...mapActions('schedules', ['sessionStart', 'sessionProgress']),
    async updateSession($event) {
      await this.$emit('track', $event)
      await this.sessionProgress([this, $event])
    },
    async next() {
      const link = format('{baseUrl}/relaxation/relaxation-show/{id}', {
        id: '{id}',
        baseUrl: process.env.VUE_APP_BASE_URL
      })

      await this.$emit('track', {
        event: 'adhere.ly:relaxation:selected',
        link
      })

      await this.$emit('go-to-next-step', {
        link,
        duration: this.params.duration
      })
    }
  }
}
</script>
