import ControlVideo from '@/kit/controls/YoutubeVideo'

export default (scope, params) => {
  return {
    name: 'youtube-video',
    controlComponent: ControlVideo,
    width: 980,
    controlProps: {
      scope,
      params,
      background: params.previewMode ? 'secondary' : undefined,
      property: 'youtube-video'
    }
  }
}
