<template>
  <v-form
    :class="['input-radio', { 'input-radio-full-width': params.fullWidth }]"
    @submit.prevent="next"
  >
    <v-row justify="center">
      <template v-if="isPreviewMode">
        <v-col class="py-0">
          <preview-component>
            <dokbot-component
              :inputs="inputs"
              :scope="scope"
              :workflow-id="params.preview"
              is-preview-mode
              :in-phone-preview="true"
            />
          </preview-component>
        </v-col>
      </template>
      <template v-else>
        <v-col>
          <dokbot-component
            :inputs="inputs"
            :scope="scope"
            :workflow-id="params.preview"
            :in-modal-preview="true"
          />
        </v-col>
      </template>

      <actions
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import format from 'string-template'
import Actions from '@/kit/components/Actions'
import PreviewComponent from '@/components/PreviewComponent'
import DokbotComponent from '@/components/DokbotComponent'
import { mapActions } from 'vuex'

export default {
  components: { PreviewComponent, DokbotComponent, Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    }
  },
  computed: {
    inputs() {
      const inputs = {
        uid: this.scope.clientId
      }

      if (this.params.inputs) {
        Object.keys(this.params.inputs).forEach(key => {
          inputs[key] = format(this.params.inputs[key], this.scope)
        })
      }

      return inputs
    },
    isPreviewMode() {
      this.params.previewMode ? this.setUXPreviewMode(true) : this.setUXPreviewMode(false)
      return this.params.previewMode
    }
  },
  created() {
    this.setUXPreviewMode(this.params.previewMode)
  },
  beforeDestroy() {
    this.resetUXState()
  },
  methods: {
    ...mapActions({
      setUXPreviewMode: 'ux/setUXPreviewMode',
      resetUXState: 'ux/resetUXState'
    }),
    async next() {
      await this.$emit('track', {
        event: `adhere.ly:dokbot:selected`,
        value: this.params.activity
      })

      let queryString = `timeId={id}&`
      Object.keys(this.inputs)
        .filter(key => key !== 'uid')
        .forEach(key => {
          if (null !== this.inputs[key]) {
            queryString += `${key}=${this.inputs[key]}&`
          }
        })

      await this.$emit('go-to-next-step', {
        dokbot: this.params.activity,
        // ...this.,
        // uncomment for localhost
        // link: `https://localhost:8080/go/${this.params.activity}/?` + queryString.slice(0, -1)
        link: `{baseUrl}/go/${this.params.activity}/?` + queryString.slice(0, -1)
        // Dokbot shows spaces incorrectly
        // link: `https://go.adhere.ly/+/${this.params.activity}?` + new URLSearchParams(this.inputs).toString()
      })
    }
  }
}
</script>
