<template>
  <v-row class="scheduled">
    <template v-if="loaded">
      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <icon-scheduled-success />
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          v-if="success"
          class="h1"
        >
          Great! Check your messages for the exercise
        </h1>
        <h1
          v-else
          class="h1"
        >
          Can't save reminder! Please, try again
        </h1>
      </v-col>
      <v-col
        v-if="showReschedule"
        cols="12"
        class="text-center"
      >
        <a
          href="https://app.adhere.ly/auth/sign-up"
          target="_blank"
          style="text-decoration:underline;"
        >Get more value if you sign up</a>
      </v-col>
    </template>

    <overlay
      v-if="!loaded"
      color="white"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Overlay from '@/components/Overlay'
import IconScheduledSuccess from '@/components/icons/IconScheduledSuccess'

export default {
  name: 'DemoScheduled',
  components: { Overlay, IconScheduledSuccess },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      success: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['user', 'isUserLoggedIn', 'tid']),
    ...mapGetters('schedules', ['item']),
    ...mapGetters('clients', ['client']),
    showReschedule() {
      return !window.xprops
    }
  },
  async mounted() {
    await this.schedule(this)
  },
  methods: {
    ...mapActions('clients', [
      'setClient',
      'clientInfo',
      'saveClient',
      'setClientNew'
    ]),
    ...mapActions('schedules', ['schedule']),
    ...mapMutations('schedules', ['updateItem']),
    async setClientFromState() {
      const info = await this.clientInfo(this.item)

      this.setClient(info)
      this.closeModal()
    },
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.scheduled {
  @media (max-width: 599px) {
    margin-top: 40px;
  }
  .h1 {
    margin-top: 20px;
    font-size: var(--workflow-scheduled-h1-font-size);
    font-weight: var(--workflow-scheduled-h1-font-weight);
    color: var(--v-primary-base);
  }
}
</style>
