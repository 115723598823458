<template>
  <v-form
    v-if="videoSelected"
    class="video"
    @submit.prevent="next"
  >
    <template v-if="isRemindType">
      <preview-relaxation-component>
        <template
          v-if="$vuetify.theme.options.logo"
          v-slot:logo
        >
          <img
            :src="$vuetify.theme.options.logo"
            alt="Adhere"
          >
        </template>
        <template v-slot:body>
          <youtube-player
            :key="key"
            :video-id="videoSelected.videoId"
            @stats="stats"
          />
        </template>

        <v-card
          v-for="video in params.videos"
          :key="video.id"
          :class="[
            'choice-item choice-item--dense',
            { selected: video.id === videoSelected.id }
          ]"
          @click="selectVideoClickHandler(video)"
        >
          <div class="choice-item-title h4 text-center">
            {{ video.label }}
          </div>
        </v-card>
      </preview-relaxation-component>
    </template>
    <template v-else>
      <v-row>
        <v-col
          cols="12"
          class="text-center"
        />
        <v-col class="col-12 col-md-12 my-0">
          <youtube-player
            :key="key"
            :video-id="videoSelected.videoId"
            @stats="stats"
          />
        </v-col>
      </v-row>

      <template>
        <v-row justify="center">
          <v-col class="d-flex py-3 col-auto">
            <v-card
              v-for="video in params.videos"
              :key="video.id"
              :class="[
                'choice-item',
                { selected: video.id === videoSelected.id }
              ]"
              @click="selectVideoClickHandler(video)"
            >
              <div class="choice-item-title h4 text-center">
                {{ video.label }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </template>

    <v-row>
      <actions
        :wrapper-class="align"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import format from 'string-template'
import Actions from '@/kit/components/Actions'
import YoutubePlayer from '@/kit/components/YoutubePlayer'
import PreviewRelaxationComponent from '@/components/PreviewRelaxationComponent'

export default {
  name: 'YoutubeVideo',
  components: { YoutubePlayer, Actions, PreviewRelaxationComponent },
  props: {
    scope: {
      type: Object,
      required: false
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: false
    },
    showPrevButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      completeOn: 70,
      notificationId: null,
      key: 1,
      mdiPlus
    }
  },
  computed: {
    ...mapGetters({
      videoSelected: 'schedules/video'
    }),
    defaultVideo() {
      return this.params.videos.find(item => item.selected)
    },
    align() {
      return this.$vuetify.breakpoint.name === 'xs'
        ? 'justify-center'
        : 'justify-end'
    },
    isRemindType() {
      this.params.previewMode ? this.setUXPreviewMode(true) : this.setUXPreviewMode(false)
      return this.params.previewMode
    }
  },
  async mounted() {
    this.setUXPreviewMode(this.params.previewMode)
    await this.updateSchedule({ video: this.defaultVideo })
  },
  beforeDestroy() {
    this.resetUXState()
  },
  methods: {
    ...mapMutations({
      updateSchedule: 'schedules/updateItem'
    }),
    ...mapActions({
      sessionStart: 'schedules/sessionStart',
      sessionProgress: 'schedules/sessionProgress',
      setUXPreviewMode: 'ux/setUXPreviewMode',
      resetUXState: 'ux/resetUXState'
    }),
    async stats($event) {
      this.$emit('track', $event)
      this.$emit('stats', $event)
      await this.sessionProgress([this, $event])
    },
    selectVideoClickHandler(video) {
      this.videoLoaded = false
      this.updateSchedule({ video })
      this.key++
    },
    async next() {
      const link = format('{baseUrl}/relaxation/youtube-video-show/{id}', {
        id: '{id}',
        baseUrl: process.env.VUE_APP_BASE_URL
      })

      this.$emit('track', {
        event: `adhere.ly:${this.property}:selected`,
        link
      })

      this.$emit('go-to-next-step', {
        link,
        duration: this.duration
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  .choice-item {
    width: 158px;
    margin-right: 20px;

    &--dense {
      width: 100px;
      margin: 0 5px 5px 5px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-container {
    position: relative;
    overflow: hidden;
  }

  &-wrap {
    width: 696px;
    max-width: 100%;
    height: 392px;
    border: none;

    &.mdOnly {
      width: 640px;
      height: 360px;
    }

    &.smAndDown {
      width: 426px;
      height: 240px;
    }
  }
}
</style>
