<template>
  <v-form @submit.prevent="next">
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>
      <v-card
        class="choice-item"
        :class="{ selected: value.id === activity.id }"
        @click="click(activity)"
      >
        <div class="choice-item-title">
          {{ activity.title }}
        </div>
        <div
          v-if="activity.text"
          class="choice-item-text"
        >
          {{ activity.text }}
        </div>
      </v-card>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Choice',
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    callback: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: {},
      changeBefore: {},
      changeAfter: {}
    }
  },
  computed: {
    ...mapGetters('clients', ['client', 'activity']),
  },
  mounted() {
    this.value = this.scope[this.property] || {}
    this.click(this.activity)
  },
  methods: {
    async click(item) {
      if (this.property === 'activity' && this.value.id !== item.id) {
        this.changeBefore = {             
          date: null,
          time: null
        }
        this.changeAfter = {
          remind: null,
          list: null
        }
      }
      await this.$emit('track', {
        event: `adhere.ly:${this.property}:${item.title}`,
        item: {
          id: item.id,
          title: item.category
        }
      })
      this.value = {
        ...item,
        date: this.scope.date || null,
        time: this.scope.time || null
      }
      this.next()
    },
    async next() {
      await this.$emit('go-to-next-step', {
        ...this.callback({ ...this.value, ...this.changeBefore }),
        ...this.changeAfter
      })
      this.changeBefore = {}
      this.changeAfter = {}
    }
  }
}
</script>
