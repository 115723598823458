<template>
  <v-form
    v-model="valid"
    @submit.prevent="next"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>
      <v-col class="col-12 pt-0 px-0 container">
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
            v-for="item in tabItems"
            :key="item"
            class="tab"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Calendar">
            <div class="list d-flex flex-wrap justify-center">
              <template v-if="!showPicker">
                <v-card
                  v-for="day in days(isDaily)"
                  :key="day"
                  :class="[
                    'choice-item text-center',
                    { selected: isSelected(day) }
                  ]"
                  @click="click(day)"
                >
                  <div class="choice-item-title">
                    {{ formatDate(day, 'ddd') }}
                  </div>
                  <div class="choice-item-text">
                    {{ formatDate(day, 'MMMM D') }}
                  </div>
                </v-card>

                <div
                  v-if="!recurrence.isRecurring"
                  class="choice-item-wrap"
                >
                  <v-card
                    v-if="isAdminOrHasSubscription"
                    :class="[
                      'choice-item text-center more',
                      { selected: customDate }
                    ]"
                    @click="togglePicker"
                  >
                    <template v-if="!customDate">
                      <div
                        class="choice-item-title"
                      >
                        More
                      </div>
                    </template>
                    <template v-else>
                      <div class="choice-item-title">
                        {{ formatDate(customDate, 'ddd') }}
                      </div>
                      <div class="choice-item-text mt-1">
                        {{ formatDate(customDate, 'MMMM D') }}
                      </div>
                      <div class="choice-item-text mt-1">
                        Change
                      </div>
                    </template>
                  </v-card>
                  <v-card
                    v-else
                    disabled
                    style="height: 68px"
                    :class="[
                      'choice-item text-center more',
                      { selected: customDate }
                    ]"
                    @click="togglePicker"
                  >
                    <template v-if="!customDate">
                      <div
                        class="choice-item-title"
                      >
                        More
                      </div>
                    </template>
                    <template v-else>
                      <div class="choice-item-title">
                        {{ formatDate(customDate, 'ddd') }}
                      </div>
                      <div class="choice-item-text mt-1">
                        {{ formatDate(customDate, 'MMMM D') }}
                      </div>
                      <div class="choice-item-text mt-1">
                        Change
                      </div>
                    </template>
                  </v-card>

                  <div
                    v-if="isAdminOrHasSubscription"
                    class="choice-item-action-button"
                  >
                    <v-btn
                      width="24"
                      height="24"
                      x-small
                      fab
                      @click.stop="resetCustomDate"
                    >
                      <v-icon>{{ mdiClose }}</v-icon>
                    </v-btn>
                  </div>
                  <div
                    v-else
                    class="choice-item-action-non-expand"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          width="24"
                          height="24"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ mdiInformation }}
                        </v-icon>
                      </template>
                      <span>Upgrade to PRO to schedule ahead of the current week</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>
              <template v-else>
                <div>
                  <div class="mb-1">
                    <v-btn
                      text
                      @click="togglePicker"
                    >
                      <v-icon class="mr-2">
                        {{ mdiKeyboardBackspace }}
                      </v-icon>
                      close date picker
                    </v-btn>
                  </div>
                  <v-date-picker
                    v-model="picker"
                    :min="min"
                    @click:date="pickerChange"
                  />
                </div>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item key="Recurrence">
            <div class="checkbox">
              <v-checkbox
                v-model="recurrenceData.isRecurring"
                :disabled="!isAdminOrHasSubscription"
              >
                <template slot="label">
                  <span class="checkbox-label">This is a recurring event</span>
                </template>
              </v-checkbox>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!isAdminOrHasSubscription"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ mdiInformation }}
                  </v-icon>
                </template>
                <span>Upgrade to PRO to activate reminder recurrence</span>
              </v-tooltip>
            </div>
            <v-row
              v-if="recurrenceData.isRecurring"
              class="recurrence-container"
            >
              <v-col class="recurrence-col">
                <v-select
                  v-model="recurrenceData.frequency"
                  :items="recurrenceItems"
                  placeholder="Choose frequency"
                />
              </v-col>
              <v-col class="recurrence-col">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="recurrenceData.endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formattedEndDate"
                      label="End date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="recurrenceData.endDate"
                    :min="nowDate"
                    :max="maxDate"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(recurrenceData.endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <actions
          :show="!!values.length"
          :label="params.nextBtnLabel"
          :icon="params.nextBtnLabel ? null : undefined"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { mdiKeyboardBackspace, mdiClose, mdiInformation } from '@mdi/js'
import moment from '@/plugins/moment'
import Actions from '@/kit/components/Actions'
import { days, hasDay } from '@/kit/helpers/datetime'
import { DateTime } from 'luxon'

export default {
  components: { Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      days,
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      nowDate: new Date().toISOString().slice(0,10),
      date: DateTime.now(),
      mdiClose,
      mdiInformation,
      mdiKeyboardBackspace,
      menu: false,
      modal: false,
      menu2: false,
      showPicker: false,
      valid: false,
      values: [],
      picker: null,
      isDaily: this.scope.activity.notificationConfig.type === 'daily',
      min: moment().format('YYYY-MM-DD'),
      recurrenceData: {
        isRecurring: false,
        frequency: '',
        endDate: ''
      },
      recurrences: null,
      recurrenceItems: [
        'Weekly',
        'Bi-weekly',
        'Monthly',
        'Quarterly',
        'Yearly'
      ],
      recurrenceMap: {
        'Weekly': {weeks: 1}, 
        'Monthly': {months: 1},
        'Bi-weekly': {weeks: 2},
        'Quarterly': {quarters: 1},
        'Yearly': {years: 1}
      },
      tab: null,
      tabItems: [
        'Calendar','Recurrence'
      ]
    }
  },
  computed: {
    ...mapGetters({
      recurrence: 'schedules/recurrence',
      user: 'authentication/user',
      hasActiveSubscription: 'subscription/hasActiveSubscription'
    }),
    customDate() {
      return this.values.find(item => !hasDay(item, this.isDaily))
    },
    formattedEndDate() {
      let ed = ''
      if (this.recurrenceData.endDate) {
        const dt = DateTime.fromISO(this.recurrenceData.endDate)
        ed = dt.toLocaleString()
      }
      return ed
    },
    isAdminOrHasSubscription() {
      return this.user.role === 'admin' || this.hasActiveSubscription
    },
    maxDate() {
     const dt = DateTime.now()
     return dt.plus({years: 1, weeks: 1}).toISODate()
    },
  },
  watch: {
    recurrenceData: {
      deep: true,
      handler(data) {
        this.recurrences = []
        this.setRecurringDates(data)
        this.updateRecurrence(data)
      }
    },
    
  },
  async mounted() {
    this.values = (this.scope[this.property] || []).slice()
    this.recurrenceData = Object.assign({}, this.recurrence)
    this.setCustomDate()
  },
  methods: {
    ...mapMutations({
      updateNotificationType: 'schedules/updateNotificationType',
      updateRecurrence: 'schedules/updateRecurrence'
    }),
    pickerChange(value) {
      this.values = this.values.filter(item => hasDay(item, this.isDaily))

      this.click(value)
      this.showPicker = false
    },
    setCustomDate() {
      this.picker = this.customDate
    },
    resetCustomDate() {
      this.values = this.values.filter(item => hasDay(item, this.isDaily))
      this.setCustomDate()
    },
    resetRecurrences() {
      this.recurrences = []
    },
    setRecurringDates(data) {
      let recurrences = []
      let totalRecurrences = []
      this.resetCustomDate()
      const multiplier = this.recurrenceMap[this.recurrenceData.frequency]
      
      if (this.recurrenceData.isRecurring && Object.values(data).every(d => !!d)) {        
        // loop through each date and add the date plus the multiplier
        // to set the future dates, i.e.
        // 2023-01-01 weekly would be that date plus 7 days until the end date
        this.values.forEach(date => {
          const endDate = DateTime.fromISO(this.recurrenceData.endDate)
          const frequency = Object.keys(multiplier)[0]
          let startDate  = DateTime.fromISO(date)
          let diff = endDate.diff(startDate, [frequency]).toObject()
          let repeats = parseInt(diff[frequency] / multiplier[frequency])
          for (let i = 0; i < repeats; i = i + 1) {
            let d = recurrences.length ? recurrences[i - 1] : date
            const nextDate = DateTime.fromISO(d).plus(multiplier)
            if (nextDate.startOf('day') <= DateTime.fromISO(this.recurrenceData.endDate).startOf('day')
              && !recurrences.includes(nextDate.toISODate())) {
              recurrences.push(nextDate.toISODate())
            }
          }
          totalRecurrences.push(recurrences)
          // reset recurrences as this is only used temporarily
          recurrences = []
        })
      } else {
        recurrences = []
        this.resetCustomDate()
      }
      this.recurrences = totalRecurrences
    },
    formatDate(date, format) {
      return moment(date, 'YYYY-MM-DD').format(format)
    },
    togglePicker() {
      this.showPicker = !this.showPicker
    },
    click(value) {
      if (this.values.find(item => item === value)) {
        this.values = this.values.filter(item => item !== value)
      } else {
        this.values.push(value)
      }
    },
    isSelected(value) {
      return !!this.values.find(item => item === value)
    },
    async next() {
      await this.$emit('track', {
        event: 'adhere.ly:date:selected',
        value: this.value
      })

      // Change notification type to once on edit daily date
      if (
        this.scope.backToStep &&
        (this.scope.activity.notificationConfig.type === 'daily' ||
          this.scope.activity.notificationConfig.type === 'random')
      ) {
        this.updateNotificationType('once')
      }
      // need to keep original dates and recurrences separate for if
      // a provider removes recurrence after selecting it
      const originalDates = [...this.values]
      // flatten recurrences as it is an array of arrays
      const recurringDates = [...this.recurrences.flat()]
      const totaldates = this.recurrenceData.isRecurring 
        ? [...new Set([...originalDates, ...recurringDates])] 
        : originalDates
      const sorted = totaldates.sort(
        (a, b) => moment(a, 'YYYY-MM-DD') - moment(b, 'YYYY-MM-DD')
      )
      await this.$emit('go-to-next-step', {
        [this.property]: sorted
      })
    }
  }
}
</script>

<style scoped>
.checkbox {
  display: flex;
  flex-direction: row;
}

>>>.v-icon.v-icon {
  align-items: start;
}
>>>.v-window__container {
  min-height: 300px;
  padding-top: 1rem;
}
.list {
  max-width: 500px;
  margin: 0 auto;
}

.checkbox-label {
  margin-right: 1rem;
}

.list .choice-item {
  position: relative;
  width: 136px;
  margin: 7px;

  @media (max-width: 599px) {
    width: 150px;
  }

  @media (max-width: 480px) {
    width: 130px;
  }
}

.container {
  margin-bottom: 12rem;
}
.recurrence-container {
  display: flex;
  flex-direction: column;
}

.recurrence-col {
  width: 100%;
}

>>>.v-input__prepend-outer {
  display: none;
}

@media(min-width: 960px) {
  .recurrence-container {
    display: flex;
    flex-direction: row;
  }

  .recurrence-col {
    width: 50%;
  }

  >>>.v-input__prepend-outer {
    display: inline-flex;
  }
}

</style>
