<template>
  <v-dialog
    v-model="dialog"
    overlay-color="rgb(31, 38, 44)"
    overlay-opacity="0.25"
    :max-width="modalWidth"
    :fullscreen="fullscreen || $vuetify.breakpoint.xsOnly"
    content-class="base-modal base-modal-full"
    style="background-color: #fff;"
    @click:outside="close(true)"
    @keydown="keydown"
  >
    <workflow
      v-if="clientData"
      :reminder="reminder"
      :client-data="clientData"
      :provider-data="providerData"
      @container-width="setContainerWidth"
      @modal-close="close(false)"
      @track="track"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Workflow from '@/kit/Workflow'

export default {
  name: 'BaseModal',
  components: { Workflow },
  props: {
    reminder: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalWidth: 900, // previous value: 780
      clientData: null
    }
  },
  computed: {
    ...mapGetters('authentication', ['providerName']),
    providerData() {
      return {
        name: this.providerName,
        roomUrl: undefined
      }
    }
  },
  async created() {
    this.clientData = await this.getClientData()
  },
  methods: {
    ...mapActions({
      getClientData: 'clients/getClientData'
    }),
    async close(track) {
      this.$emit('modal-close')

      if (track) {
        this.track({ event: 'adhere.ly:closed' })
      }
    },
    keydown(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    },
    setContainerWidth(value = 900) { // previous value: 780
      this.modalWidth = value
    },
    track(data) {
      const event = data.event

      delete data.event

      this.$firebase.analytics.logEvent(event, data)
    }
  }
}
</script>

<style lang="scss">
@media (min-width: 600px) {
  .base-modal.base-modal-full {
    // max-height: 96%;
    
    // Changing this to 100% fixes the exposed footer issue in the demo
    max-height: 100%;
  }
}
</style>
