<template>
  <div class="custom-time-picker">
    <v-row>
      <v-col class="col-12">
        <div class="picker">
          <input
            ref="hour"
            :value="inputValues.hour"
            type="text"
            class="hour"
            v-on="inputListeners('hour')"
          >
          <div class="picker-divider">
            :
          </div>
          <input
            ref="minutes"
            :value="inputValues.minutes"
            type="text"
            class="minutes"
            v-on="inputListeners('minutes')"
          >
          <div class="picker-ampm">
            <div
              v-for="period in periods"
              :key="period"
              :class="[
                'picker-ampm-item',
                { active: period === inputValues.period }
              ]"
              @click="toggleFormat(period)"
            >
              {{ period }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      justify="space-between"
      align="center"
    >
      <v-col class="col-auto pb-0">
        <v-btn
          text
          color="primary"
          @click="$emit('cancel')"
        >
          Cancel
        </v-btn>
      </v-col>
      <v-col class="col-auto pb-0">
        <v-btn
          x-large
          color="primary"
          @click="$emit('input', value)"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from '@/plugins/moment'

const periods = ['AM', 'PM']
const isValid = {
  hour: s => !!s.match(/^([1-9]|1[0-2])$/),
  minutes: s => !!s.match(/^([1-9]|0[0-9]?|[1-5][0-9])$/)
}

export default {
  name: 'CustomTimePiker',
  props: {
    time: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      periods,
      dirty: false,
      inputValues: {
        hour: '4',
        minutes: '00',
        period: periods[0]
      },
      key: null
    }
  },
  computed: {
    isValidHour() {
      return (
        isValid.hour(this.inputValues.hour) &&
        !!this.inputValues.hour.toString().length
      )
    },
    isValidMinutes() {
      return (
        isValid.minutes(this.inputValues.minutes) &&
        !!this.inputValues.minutes.toString().length
      )
    },
    isValidTime() {
      return this.isValidHour && this.isValidMinutes
    },
    value() {
      if (this.isValidTime) {
        return moment(
          `${this.inputValues.hour}:${this.inputValues.minutes} ${this.inputValues.period}`,
          'HH:mm A'
        ).format('HH:mm')
      }

      return null
    }
  },
  beforeMount() {
    if (this.time) {
      const time = moment(
        this.formatTimeUtcToLocal(this.time, 'HH:mm'),
        'HH:mm'
      )

      this.inputValues.hour = time.format('h')
      this.inputValues.minutes = time.format('mm')
      this.inputValues.period = time.format('A')
    }
  },
  methods: {
    inputListeners(ref) {
      return {
        ...this.$listeners,
        input: e => this.handleInput(e.target.value, ref),
        keydown: e => this.handleKeydown(e, ref),
        click: () => this.select(ref),
        wheel: e => this.handleWheel(e, ref)
      }
    },
    handleInput(value, ref) {
      if (
        isValid[ref](value) ||
        this.key === 'Backspace' ||
        this.key === 'Delete'
      ) {
        this.inputValues[ref] = value
        this.toggleFocus(ref)
      } else {
        this.$refs[ref].value = this.inputValues[ref]
      }

      this.dirty = !this.isValidTime
    },
    handleKeydown(event, ref) {
      this.key = event.code

      if (this.key === 'Backspace' || this.key === 'Delete') {
        this.handleKeydownDelete(event, ref)
      }

      if (this.key === 'ArrowUp') {
        this.handleInput(this.increment(ref), ref)
        event.preventDefault()
      }

      if (this.key === 'ArrowDown') {
        this.handleInput(this.decrement(ref), ref)
        event.preventDefault()
      }
    },
    select(ref) {
      this.$refs[ref].select()
    },
    toggleFormat(value) {
      this.inputValues.period = value
    },
    handleKeydownDelete(event, ref) {
      if (event.target.value.toString().length === 0) {
        if (ref === 'minutes') {
          this.$refs.hour.focus()
        }

        event.preventDefault()
      }
    },
    handleWheel(event, ref) {
      this.key = 'Wheel'

      this.handleInput(
        event.deltaY < 0 ? this.increment(ref) : this.decrement(ref),
        ref
      )
    },
    toggleFocus(ref) {
      if (
        this.key !== 'ArrowUp' &&
        this.key !== 'ArrowDown' &&
        this.key !== 'Wheel'
      ) {
        if (
          ref === 'hour' &&
          this.isValidHour &&
          this.inputValues.hour.toString().length === 2
        ) {
          this.$refs.minutes.select()
        }
      }
    },
    increment(ref) {
      let value = (+this.inputValues[ref] + 1).toString()

      if (ref === 'minutes' && value.length < 2) {
        value = `0${value}`
      }

      return value
    },
    decrement(ref) {
      let value = (+this.inputValues[ref] - 1).toString()

      if (ref === 'minutes' && value.length < 2) {
        value = `0${value}`
      }

      return value
    }
  }
}
</script>

<style scoped lang="scss">
@import '../theme/functions';

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max) {
  font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
}

.custom-time-picker {
  .picker {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 80px;
    padding: 40px 30px;
    border-radius: 15px;
    background: var(--v-secondary-base);

    @media (max-width: 599px) {
      padding: 30px 20px;
    }

    @media (max-width: 479px) {
      padding: 20px 15px;
    }

    @media (max-width: 359px) {
      padding: 15px;
    }

    input,
    &-divider {
      font-size: 120px;
      font-weight: 300;
      line-height: 0.8;

      @media (max-width: 599px) {
        @include fluidFontSize(75px, 128px, 320px, 599px);
      }
    }

    input {
      width: 170px;
      height: 120px;
      padding-right: 14px;
      letter-spacing: -5px;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;

      @media (max-width: 599px) {
        width: calc((100% - 105px) / 2);
        height: 100px;
        padding-right: 10px;
      }

      @media (max-width: 479px) {
        width: calc((100% - 58px) / 2);
        height: 90px;
        padding: 0 7px;
      }

      &.hour {
        text-align: right;
      }

      &.minutes {
        @media (min-width: 600px) {
          text-align: center;
        }
      }

      &:focus,
      &:active {
        outline: none !important;
        background-color: transparent !important;
      }
    }

    &-divider {
      @media (max-width: 599px) {
        width: 40px;
        text-align: center;
      }

      @media (max-width: 479px) {
        width: 15px;
      }
    }

    &-ampm {
      padding-left: 14px;
      font-size: 36px;

      @media (max-width: 599px) {
        width: 70px;
      }

      @media (max-width: 479px) {
        width: 43px;
        padding-left: 5px;
        font-size: 24px !important;
      }

      &-item {
        color: var(--v-neutralMedium-base);
        text-transform: uppercase;

        &.active {
          color: var(--v-primary-base);
        }

        &:not(.active) {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
