// copied from src/kit/types/video.js to define
// new type for audio exercises
import ControlAudio from '@/kit/controls/Audio'

export default (scope, params) => {
  return {
    name: 'audio',
    controlComponent: ControlAudio,
    width: 980,
    controlProps: {
      scope,
      params,
      background: params.previewMode ? 'secondary' : undefined,
      property: 'audio'
    }
  }
}