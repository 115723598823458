import ControlDatePicker from '@/kit/controls/DatePicker'

export default (scope, params) => {
  return {
    name: 'date',
    controlComponent: ControlDatePicker,
    controlProps: {
      scope,
      params,
      property: 'date',
      title:
        scope.activity.notificationConfig.type === 'appointment'
          ? "What day is your client's next appointment?"
          : 'What day should your client be reminded?'
    }
  }
}
