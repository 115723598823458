import format from 'string-template'
import emailValidation from '@/helpers/emailValidation'
import ControlInputText from '@/kit/controls/InputText'

export default (scope, params) => {
  return {
    name: 'email',
    controlComponent: ControlInputText,
    controlProps: {
      scope,
      params,
      type: 'email',
      autocomplete: '',
      background: params.background || 'secondary',
      property: 'email',
      title: params.titleEmail ?
        params.titleEmail:
        format('Enter <b>{name}’s</b> email address', scope),
      placeholder: 'Enter email',
      rules: [v => !!v || 'Email is required', v => emailValidation(v)],
      maxLength: 50,
      privacy: true
    }
  }
}
