var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.videoSelected)?_c('v-form',{staticClass:"video",on:{"submit":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}},[(_vm.isRemindType)?[_c('preview-relaxation-component',{scopedSlots:_vm._u([(_vm.$vuetify.theme.options.logo)?{key:"logo",fn:function(){return [_c('img',{attrs:{"src":_vm.$vuetify.theme.options.logo,"alt":"Adhere"}})]},proxy:true}:null,{key:"body",fn:function(){return [_c('youtube-player',{key:_vm.key,attrs:{"video-id":_vm.videoSelected.videoId},on:{"stats":_vm.stats}})]},proxy:true}],null,true)},_vm._l((_vm.params.videos),function(video){return _c('v-card',{key:video.id,class:[
          'choice-item choice-item--dense',
          { selected: video.id === _vm.videoSelected.id }
        ],on:{"click":function($event){return _vm.selectVideoClickHandler(video)}}},[_c('div',{staticClass:"choice-item-title h4 text-center"},[_vm._v(" "+_vm._s(video.label)+" ")])])}),1)]:[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}}),_c('v-col',{staticClass:"col-12 col-md-12 my-0"},[_c('youtube-player',{key:_vm.key,attrs:{"video-id":_vm.videoSelected.videoId},on:{"stats":_vm.stats}})],1)],1),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex py-3 col-auto"},_vm._l((_vm.params.videos),function(video){return _c('v-card',{key:video.id,class:[
              'choice-item',
              { selected: video.id === _vm.videoSelected.id }
            ],on:{"click":function($event){return _vm.selectVideoClickHandler(video)}}},[_c('div',{staticClass:"choice-item-title h4 text-center"},[_vm._v(" "+_vm._s(video.label)+" ")])])}),1)],1)]],_c('v-row',[_c('actions',{attrs:{"wrapper-class":_vm.align,"label":_vm.params.nextBtnLabel,"icon":_vm.params.nextBtnLabel ? null : undefined}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }