import format from 'string-template'
import ControlChoice2 from '@/kit/controls/Choice2'

export default (scope, params) => {
  return {
    name: 'choice',
    controlComponent: ControlChoice2,
    controlProps: {
      scope,
      params,
      property: 'choice',
      title: format(params.title, {
        name: scope.clientName,
        remind: scope.remind,
        caregiverName: scope.caregiverName,
        choiceValue: scope.choiceValue
      }),
      items: params.items,
      callback: value => ({
        choice: value,
        choiceName: value.title,
        choiceValue: value.value,
        choiceExample: value.example,
        choiceExample2: value.example2
      })
    }
  }
}
