<template>
  <v-form @submit.prevent="next">
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>
      <v-col class="col-12 pb-0">
        <div class="pickers">
          <v-menu
            v-model="menuDatePicker"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            nudge-bottom="4"
            min-width="290"
          >
            <template v-slot:activator="{ on }">
              <div
                class="date-picker"
                v-on="on"
              >
                <v-text-field
                  :value="viewDate"
                  solo
                  required
                  readonly
                  hide-details
                  height="var(--input-height)"
                  :placeholder="datePlaceholder"
                />
              </div>
            </template>

            <v-date-picker
              v-model="datePicker"
              no-title
              scrollable
              :min="minDate"
              @click:date="menuDatePicker = false"
            />
          </v-menu>
          <v-menu
            v-model="menuTimePicker"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            nudge-bottom="4"
            max-width="152"
            max-height="205"
          >
            <template v-slot:activator="{ on }">
              <div
                class="time-picker"
                v-on="on"
              >
                <v-text-field
                  :value="viewTime"
                  solo
                  required
                  readonly
                  hide-details
                  height="var(--input-height)"
                  :placeholder="timePlaceholder"
                />
              </div>
            </template>

            <v-sheet class="time-picker-dropdown">
              <div
                v-for="item in times"
                :key="item"
                :class="['time-picker-item', { selected: timePicker === item }]"
                @click="clickTimePickerHandler(item)"
              >
                <span>{{ formatTimeLocal(item) }}</span>
              </div>
            </v-sheet>
          </v-menu>
        </div>
      </v-col>
      <v-col
        v-if="subtitle"
        class="col-12 text"
      >
        {{ subtitle }}
      </v-col>

      <actions
        :show="!!valuesDate.length && !!valuesTime.length"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import { mdiKeyboardBackspace } from '@mdi/js'
import moment from '@/plugins/moment'
import Actions from '@/kit/components/Actions'
import { periodGenerator } from '@/kit/helpers/datetime'

export default {
  name: 'DateTimePicker',
  components: { Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      mdiKeyboardBackspace,
      menuDatePicker: false,
      menuTimePicker: false,
      datePicker: null,
      timePicker: null,
      minDate: moment().format('YYYY-MM-DD'),
      timesPeriod: periodGenerator(
        '00:00',
        'HH:mm',
        24,
        'hour',
        15,
        'minute',
        'HH:mm'
      )
    }
  },
  computed: {
    times() {
      let times = [...this.timesPeriod]

      if (this.isSameDate) {
        times = times.filter(item => moment().isBefore(moment(item, 'HH:mm')))
      }

      return times
    },
    viewDate() {
      return this.datePicker
        ? moment(this.datePicker).format('dddd MMMM DD YYYY')
        : null
    },
    viewTime() {
      return this.timePicker ? this.formatTimeLocal(this.timePicker) : null
    },
    valuesDate() {
      return this.datePicker ? new Array(this.datePicker) : []
    },
    valuesTime() {
      return this.timePicker
        ? new Array(this.formatTimeUTC(this.timePicker))
        : []
    },
    datePlaceholder() {
      return moment().format('dddd MMMM DD YYYY')
    },
    timePlaceholder() {
      return moment().format('HH:mm A')
    },
    isSameDate() {
      return moment().isSame(moment(this.datePicker, 'YYYY-MM-DD'), 'date')
    },
    isResetTime() {
      return (
        this.isSameDate && moment().isAfter(moment(this.timePicker, 'HH:mm'))
      )
    }
  },
  watch: {
    isResetTime: function() {
      this.timePicker = null
    }
  },
  mounted() {
    const dates = (this.scope.date || []).slice()
    const times = (this.scope.time || []).slice()

    this.datePicker = dates.length ? dates[0] : null
    this.timePicker = times.length
      ? this.formatTimeUtcToLocal(times[0], 'HH:mm')
      : null
  },
  methods: {
    clickTimePickerHandler(value) {
      this.timePicker = value
      this.menuTimePicker = false
    },
    async next() {
      await this.$emit('track', {
        event: 'adhere.ly:datetime:selected',
        value: {
          date: this.valuesDate,
          time: this.valuesTime
        }
      })

      await this.$emit('go-to-next-step', {
        date: this.valuesDate,
        time: this.valuesTime
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-picker {
  border-radius: 0 !important;
}

.pickers {
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.date-picker {
  width: 100%;
  max-width: calc(68% - 30px);
  margin: 0 30px 10px 0;

  @media (max-width: 500px) {
    max-width: 100%;
    margin-right: 0;
  }
}

.time-picker {
  width: 100%;
  max-width: 32%;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    width: 100%;
    max-width: 100%;
  }

  &-item {
    padding: 10px 0;
    font-size: 14px;

    &:not(.selected) {
      cursor: pointer;
    }

    &.selected {
      background-color: #f1f3f4;
    }

    span {
      padding: 0 8px;
    }
  }
}

.text {
  text-align: center;
  font-size: 14px;
}
</style>

<style>
.pickers .v-text-field > .v-input__control > .v-input__slot,
.pickers .v-text-field > .v-input__control > .v-input__slot input {
  cursor: pointer !important;
}
</style>
