import RelaxationChoose from '@/kit/controls/RelaxationChoose'

export default (scope, params) => {
  return {
    name: 'relaxationChoose',
    controlComponent: RelaxationChoose,
    width: 1200,
    controlProps: {
      scope,
      params,
      background: params.previewMode ? 'secondary' : undefined,
      property: 'relaxationChoose'
    }
  }
}
