import format from 'string-template'
import ControlInputText from '@/kit/controls/InputText'

export default (scope, params) => {
  return {
    name: 'phone',
    controlComponent: ControlInputText,
    controlProps: {
      scope,
      params,
      property: 'phone',
      background: params.background || 'secondary',
      type: 'tel',
      autocomplete: '',
      title: params?.titlePhone ?
        params.titlePhone :
        format('Enter <b>{name}’s</b> mobile number', scope),
      placeholder: 'Mobile number',
      rules: [
        v => !!v || 'Phone number is required',
        v => /\d{10}/.test(v.replace(/\D/g, '')) || 'Phone number must be valid'
      ],
      maxLength: 20,
      privacy: true
    }
  }
}
