import ControlDateTimePicker from '@/kit/controls/DateTimePicker'

export default (scope, params) => {
  return {
    name: 'dateTime',
    controlComponent: ControlDateTimePicker,
    controlProps: {
      scope,
      params,
      background: params.background || 'secondary',
      property: 'dateTime',
      title: params.title || 'What time should your client be reminded?',
      subtitle: params.subtitle,
      timesLimit: params.timesLimit,
      privacy: true
    }
  }
}
