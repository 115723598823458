<template>
  <svg
    width="182"
    height="182"
    viewBox="0 0 182 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="91"
      cy="91"
      r="90.5"
      stroke="#FF9175"
      stroke-opacity="0.3"
    />
    <circle
      cx="91.0008"
      cy="91.0008"
      r="66.3153"
      stroke="#FF9175"
      stroke-opacity="0.5"
      stroke-width="3"
    />
    <circle
      cx="91.5788"
      cy="91.5788"
      r="53.3248"
      fill="#FF9175"
    />
    <rect
      x="78.701"
      y="74.625"
      width="7.53216"
      height="34.8588"
      fill="white"
    />
    <rect
      x="97.707"
      y="74.625"
      width="7.53216"
      height="34.8588"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPauseFilled'
}
</script>
