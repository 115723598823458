import ControlDokbot from '@/kit/controls/Dokbot'

export default (scope, params) => {
  if (scope.dokbotParams) {
    params = { ...params, ...scope.dokbotParams }
  }

  return {
    name: 'dokbot',
    controlComponent: ControlDokbot,
    controlProps: {
      scope,
      params,
      background: params.previewMode ? 'secondary' : undefined,
      property: 'dokbot'
    }
  }
}
