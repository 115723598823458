<template>
  <v-row
    :class="[
      'relaxation-balloon no-gutters',
      { 'relaxation-balloon--dense': dense }
    ]"
  >
    <v-col cols="12">
      <div class="d-flex justify-center">
        <div
          :class="['image-wrap', { clickable: timeLeft }]"
          @click="click"
        >
          <div class="image-wrap-helper">
            <div
              class="balloon"
              :style="{
                animationDuration: animationDuration + 's',
                animationPlayState: animationPlayState
              }"
            >
              <img
                :src="balloon"
                alt="deep breathing exercise"
              >
              <div
                v-if="timeLeft"
                class="control-button"
              >
                <icon-play v-if="!animation" />
                <icon-pause v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="col-12 relaxation-timer text-center">
      {{ clock }}
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import balloon from '@/assets/img/balloon.svg'
import IconPlay from '@/components/icons/IconPlay'
import IconPause from '@/components/icons/IconPause'

/**
 * Although this audio player generic has built-in session progress code,
 * is it not used for deep breathing and is intended to allow audio
 * playback to work using the current system.
 * 
 * Old: DeepBreathing from kit/helpers/deepBreathing
 * New: AudioPlayer from kit/helpers/AudioPlayer
 *      this.audioPlayer = new AudioPlayer('/media/deep-breathing.mp3')
 * 
 * Old: DeepBreathing.play()
 * New: this.audioPlayer.play()
 * 
 * Old: this.playerState = 'play'
 * New: this.audioPlayer.play()
 */
import AudioPlayer from '@/kit/helpers/AudioPlayer'

export default {
  name: 'RelaxationBalloon',
  components: { IconPlay, IconPause },
  props: {
    duration: {
      type: Number,
      required: true
    },
    timeId: {
      type: String,
      required: false,
      default: () => null
    },
    session: {
      type: Object,
      required: false,
      default: () => null
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      balloon,
      completeOn: 70,
      notificationId: this.timeId,
      animationDuration: 10,
      timePassed: 0,
      animation: false,
      intervalId: null,
      trackIntervalId: null,
      sessionRef: null,
      playerState: 'stop',
      activityDuration: 0,
      animationPlayState: 'paused',
      timeLeft: 0,
      audioPlayer: null,
    }
  },
  computed: {
    clock() {
      const minutes = Math.floor(this.timeLeft / 60)
      const seconds = this.timeLeft % 60

      return minutes + ':' + (seconds <= 9 ? '0' + seconds : seconds)
    }
  },
  watch: {
    playerState: function() {
      switch (this.playerState) {
        case 'play':
          this.audioPlayer.play()
          break
        case 'stop':
          this.audioPlayer.stop()
          break
        case 'pause':
          this.audioPlayer.pause()
          break
        default:
          return false
      }
    }
  },
  created() {
    this.timeLeft = this.duration
    this.audioPlayer = new AudioPlayer('/media/deep-breathing.mp3')
  },
  beforeDestroy() {
    this.audioPlayer.stop()
    this.clear()
  },
  methods: {
    ...mapActions('schedules', ['sessionProgress']),
    click() {
      if (this.timeLeft < 1) {
        return false
      }

      this.animation = !this.animation

      if (this.animation) {
        this.intervalId = setInterval(() => {
          this.timePassed++

          if (this.timeLeft-- <= 1) {
            this.animation = false
            this.clear()
            this.playerState = 'stop'
            this.animationPlayState = 'paused'
          }
        }, 1000)

        this.trackIntervalId = setInterval(this.updateSession, 3000)

        this.playerState = 'play'
        this.animationPlayState = 'running'
      } else {
        this.clear()
        this.playerState = 'pause'
        this.animationPlayState = 'paused'
      }
    },
    async updateSession() {
      const $event = {
        duration: this.duration,
        timePassed: this.timePassed
      }
      this.$emit('stats', $event)
      await this.sessionProgress([this, $event])
    },
    clear() {
      this.updateSession()
      clearInterval(this.intervalId)
      clearInterval(this.trackIntervalId)
      this.timePassed = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.relaxation-balloon {
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    45% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.2);
    }
    95% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  .image-wrap {
    width: 100%;
    max-width: 450px;
    margin: 0 auto 0;
    z-index: 10;

    
    @media (max-height: 710px) {
      margin: -6rem auto 0;
    }

    @media (max-width: 959px) {
      margin: -1rem auto 0;
    }
    
    &.clickable {
      cursor: pointer;
    }

    &-helper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .balloon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      width: 78%;
      height: 78%;
      margin-top: -39%;
      margin-left: -39%;
      animation-name: pulse;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .control-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 162px;
    height: 162px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 599px) {
      width: 90px;
      height: 90px;
    }

    svg {
      height: auto;
    }
  }

  .relaxation {
    &-timer {
      font-size: 48px;
      font-weight: 300;
      color: var(--v-neutralMedium-base);
      line-height: 1;

      @media (max-width: 959px) {
        font-size: 42px;
      }

      @media (max-width: 599px) {
        font-size: 28px;
      }
    }
  }

  &--dense {
    .control-button {
      width: 80px;
      height: 80px;
    }

    .relaxation {
      &-timer {
        font-size: 28px;
        font-weight: 300;
        color: var(--v-neutralMedium-base);
        line-height: 1;
      }
    }
  }
}
</style>
