// Helper class for Video component
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
// Props: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement

export default class VideoPlayer {

  clockString = 'Loading'
  videoPlayer = null
  updateHandler = null

  /**
   * VideoPlayer constructor that takes a video.js instance
   * @param {Function} videoJSPlayer Declaired in VideoPlayer component
   */
  constructor(videoJSPlayer) {
    this.videoPlayer = videoJSPlayer
    this.videoPlayer.load()

    // Triggers every time the media progresses
    this.updateHandler = () => {
      if (this.getTimeRemaining() >= Infinity) return

      const minutes = Math.floor(this.getTimeRemaining() / 60)
      const seconds = this.getTimeRemaining() % 60

      if (isNaN(minutes) || isNaN(seconds))
        return this.clockString = 'Loading'

      this.clockString = minutes + ':' + (seconds <= 9 ? '0' + seconds : seconds)
    }

    // Update the clock when the media is ready
    // If you call updateHandler sooner you will get undefined for times
    this.once('loadedmetadata', () => {
      // Server doesn't send Content-range header so we must force-load the whole thing
      // https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity
      this.updateHandler()
    })
    
    this.once('loadeddata', () => {
      this.updateHandler()
    })

    this.on('timeupdate', this.updateHandler)
  }

  /**
   * Clean up the video component/unload
   * Call this before leaving a page, with beforeUnmount (Vue3 compatible)
   */
  destroy() {
    // Stop the video before leaving, else it will continue to play
    // (it does not get gc'd until pause/finished even when unref'd)
    this.stop()
    this.off(this.updateHandler)
  }

  async play() {
    await this.videoPlayer.play()
  }
  async pause() {
    await this.videoPlayer.pause()
  }
  async stop() {
    await this.videoPlayer.pause()
  }

  /**
   * @returns {string} Auto generated clock string using media data. The value updates live, call this method whenever you want a refresh.
   */
  getClockString() {
    return this.clockString
  }

  /**
   * @returns {number} Time passed in seconds as an integer
   */
  getTimePassed() {
    return Math.round(this.videoPlayer.currentTime()) // VideoJS returns seconds in ints
  }

  /**
   * @returns {number} Time remaining in seconds as an integer
   */
  getTimeRemaining() {
    return Math.round(this.videoPlayer.duration() - this.videoPlayer.currentTime()) // VideoJS returns seconds in ints
  }

  /**
   * @returns {number} Total time in seconds of the media
   */
  getDuration() {
    return Math.round(this.videoPlayer.duration())
  }

  // Nodejs style handler register helpers
  // Remember, callback must always be the same function reference
  // For videojs: Use different functions for event targets: https://videojs.com/guides/event-target/

  // Video player can be destroyed in the DOM before the "off" can be called anyway
  // This differs from the audio component because Vue deletes the component and refs before navigation away
  // Audio classes exist outside the dom that Vue controls so in VideoPlayer we clean up on our own

  /**
   * Add an event listener to the audio player
   * @param eventName - The name of the event you want to listen for.
   * @param callback - The function to be called when the event is fired.
   */
  on(eventName, callback) {
    if (!this.videoPlayer) return
    this.videoPlayer.on(eventName, callback)
  }

  /**
   * It removes an event listener from the audio player
   * @param {string} eventName - The name of the event you want to listen for
   * @param {function} callback - The function reference that you want to remove from the listener
   */
  off(eventName, callback) {
    if (!this.videoPlayer) return
    this.videoPlayer.off(eventName, callback)
  }

  // /**
  //  * Triggers a callback when an event happens only once, then removes the lsitener
  //  * @param {string} eventName Name of event to listen to
  //  * @param {function} callback Function to call when event is triggered
  //  */
  once(eventName, callback) {
    // Must be an arrow function to access *this*
    const eventHandler = () => {
      if (!this.videoPlayer) return

      callback()
      this.off(eventName, callback)
    }
    this.on(eventName, eventHandler)
  }
}