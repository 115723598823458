import format from 'string-template'
import ControlInputRadio from '@/kit/controls/InputRadio'

export default (scope, params) => {
  return {
    name: 'list',
    controlComponent: ControlInputRadio,
    controlProps: {
      scope,
      params,
      property: 'list',
      title: format(params.title, {
        name: scope.clientName,
        caregiverName: scope.caregiverName
      })
    }
  }
}
