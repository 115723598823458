import RollBreathing from '@/kit/controls/Video'

export default (scope, params) => {
  return {
    name: 'rollBreathing',
    controlComponent: RollBreathing,
    width: 1200,
    controlProps: {
      scope,
      params,
      background: params.previewMode ? 'secondary' : undefined,
      property: 'rollBreathing'
    }
  }
}