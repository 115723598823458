var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"h1",domProps:{"innerHTML":_vm._s(!_vm.showPicker ? _vm.title : _vm.pickerTitle)}})]),_c('v-col',{class:['col-12 pt-0 px-0', { 'pb-0': _vm.showPicker }]},[(!_vm.showPicker)?_c('div',{staticClass:"list d-flex flex-wrap justify-center"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"choice-item-wrap"},[_c('v-card',{class:[
              'choice-item text-center',
              { selected: _vm.isSelected(item), disabled: _vm.limited() }
            ],on:{"click":function($event){return _vm.click(item)}}},[_c('div',{staticClass:"choice-item-title"},[_vm._v(" "+_vm._s(_vm.formatTimeLocal(item))+" ")])]),(
              index === _vm.items.length - 1 &&
                (_vm.isSelected(item) ? true : !_vm.limited())
            )?_c('div',{staticClass:"choice-item-action-button"},[_c('v-btn',{attrs:{"width":"24","height":"24","x-small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.togglePicker.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1)],1):_vm._e()],1)}),0):_c('custom-time-piker',{attrs:{"time":_vm.customTime},on:{"input":_vm.pickerChange,"cancel":function($event){_vm.showPicker = false}}})],1),(!_vm.showPicker)?_c('actions',{attrs:{"show":!!_vm.values.length,"label":_vm.params.nextBtnLabel,"icon":_vm.params.nextBtnLabel ? null : undefined}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }