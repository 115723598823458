<template>
  <div class="summary-header">
    <div
      v-if="scope.channel.id === 1"
      class="summary-header-sms text-center"
    >
      {{ firstDate }} {{ firstTime }}
    </div>

    <div
      v-if="scope.channel.id === 2"
      class="summary-header-email"
    >
      <b>From:</b> {{ scope.emailFrom }} <br>
      <b>Sent:</b> {{ firstDate }} {{ firstTime }} <br>
      <b>To:</b> {{ clientName }} ({{ scope.email }}) <br>
      <b>Subject:</b> {{ subject }}
    </div>
  </div>
</template>

<script>
import moment from '@/plugins/moment'

export default {
  name: 'SummaryHeaders',
  props: {
    scope: {
      type: Object,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    times: {
      type: Array,
      required: true
    }
  },
  computed: {
    firstDate() {
      return this.dateFormat(
        moment(this.dates[0]).add(
          this.scope.activity.notificationConfig.relativeDate || 0,
          'hour'
        )
      )
    },
    firstTime() {
      const datetime = moment
        .utc(this.dates[0] + ' ' + this.times[0], 'YYYY-MM-DD HH:mm')
        .add(this.scope.activity.notificationConfig.relativeDate || 0, 'hour')

      // const time = moment(datetime.local().format('YYYY-MM-DD HH:mm')).isAfter(
      //   moment().format('YYYY-MM-DD HH:mm')
      // )
      //   ? moment.utc(datetime.format('HH:mm'), 'HH:mm')
      //   : moment().utc()

      return this.formatTimeLocal(moment.utc(datetime.format('HH:mm'), 'HH:mm'))
    },
    clientName() {
      if (true === this.scope?.caregiver) {
        return this.scope.caregiverName || this.scope.name
      }

      return this.scope.name
    },
    subject() {
      const str = this.scope.providerName

      return `A friendly reminder from ${str}`
    }
  }
}
</script>
