<template>
  <v-card
    v-if="type"
    v-resize="resizeHandler"
    class="workflow"
    :class="'workflow-name-' + schedule.step"
  >
    <header
      ref="workflowHeader"
      class="workflow-header headline justify-center flex-column"
    >
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          no-gutters
          align="center"
          justify="space-between"
        >
          <!-- <v-col class="col-2">
            <v-btn
              v-if="!showPrevButton"
              text
              min-width="36"
              height="36"
              class="pa-0"
              data-cy="prev"
              @click="goToPreviousStep"
            >
              <icon-arrow-left fill="var(--v-primary-base)" />
            </v-btn>
          </v-col> -->

          <!-- Logo will be commented out until further notice -->
          <v-col class="col-auto">
            <!-- <logo-modal 
              v-if="
                isShowLogo && !(type.controlProps.params.reminder.isDemo === true)
              "
            /> -->
          </v-col> 

          <v-col class="col-2 text-right">
            <!-- <v-btn
              v-if="
                dialog !== false && type.controlProps.showCloseButton !== false
              "
              text
              min-width="36"
              height="36"
              class="pa-0 dialog-close-button"
              data-cy="close"
              @click="close"
            > -->
            <v-btn
              v-if="!(type.controlProps.params.reminder.isDemo === true)"
              text
              min-width="36"
              height="36"
              class="pa-0 dialog-close-button"
              data-cy="close"
              @click="close"
            >
              <icon-close fill="var(--v-primary-base)" />
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </header>

    <section
      ref="workflowBody"
      :class="
        activityType === 'video' && !isPreviewMode && activityTitle !== 'Deep breathing' ?
          'workflow-body-video' :
          'workflow-body'"
      :style="bodyStyles"
    >
      <div
        class="workflow-body-wrap"
      >
        <v-container
          :is="type.controlComponent"
          :key="key"
          v-bind="type.controlProps"
          @go-to-next-step="goToNextStep"
          @go-to-step="goToStep"
          @close-modal="close"
          @track="$emit('track', $event)"
        />
      </div>
    </section>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LogoModal from '@/components/LogoModal'
import IconArrowLeft from '@/components/icons/IconArrowLeft'
import IconClose from '@/components/icons/IconClose'
import TypeLoader from '@/kit/TypeLoader'

export default {
  name: 'Workflow',
  components: { LogoModal, IconArrowLeft, IconClose },
  props: {
    reminder: {
      type: Object,
      required: true
    },
    clientData: {
      type: Object,
      default: () => ({})
    },
    providerData: {
      type: Object,
      default: () => ({})
    },
    emailFrom: {
      type: String,
      default: () => 'Adhere.ly Reminders <reminders@adhere.ly>'
    },
    serviceName: {
      type: String,
      default: () => 'default'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    smsConsentEnabled: {
      type: Boolean,
      default: false
    },
    smsConsent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: null,
      steps: ['activity'],
      tl: null,
      key: 1,
      bodyStyles: {},
    }
  },
  computed: {
    ...mapGetters({
      schedule: 'schedules/item',
      editType: 'schedules/editType',
      isUserLoggedIn: 'authentication/isUserLoggedIn',
      activityType: 'ux/activityType',
      isPreviewMode: 'ux/isPreviewMode',
      activityTitle: 'ux/activityTitle'
    }),
    activityId() {
      return this.schedule.activity ? this.schedule.activity.id : null
    },
    showPrevButton() {
      const step = this.tl.getStep(
        this.steps[this.steps.length - 1],
        this.activityId
      )

      return this.steps.length === 1 || step.name === 'scheduled'
    },
    showPrivacy() {
      return this.type.controlProps.privacy
    },
    isShowLogo() {
      return (
        !this.type.controlProps.params.previewMode &&
        (this.schedule.step !== 'scheduled' ||
          (this.$vuetify.breakpoint.xsOnly && this.schedule.step !== 'dokbot'))
      )
    }
  },
  watch: {
    type: function() {
      this.$nextTick(() => this.setBodyStyle())
    }
  },
  async created() {
    await this.$emit('track', { event: 'adhere.ly:created' })
    this.tl = new TypeLoader(this.reminder)
    const caregiver = this.reminder.caregiver || false
    const data = {
      ...this.clientData,
      caregiver,
      providerName: this.providerData.name || 'your provider',
      caregiverName: this.clientData.caregiverName || 'caregiver',
      clientName: this.clientData.name || 'your client',
      roomUrl: this.providerData.roomUrl,
      reminder: this.reminder.id,
      trigger: this.reminder.trigger || null,
      category: this.reminder.category || this.reminder.id,
      reminderType: this.reminder.reminderType,
      serviceName: this.serviceName,
      smsConsentEnabled: this.smsConsentEnabled,
      smsConsent: this.smsConsent,
      emailFrom: this.emailFrom,
      isUserLoggedIn: this.isUserLoggedIn
    }

    if (caregiver) {
      data['email'] = this.clientData.caregiverEmail || this.clientData.email
      data['phone'] = this.clientData.caregiverPhone || this.clientData.phone
      // data['name'] = this.clientData.caregiverName || this.clientData.name
    }
    await this.updateSchedule(data)
    await this.createType('activity')
  },
  destroyed() {
    this.resetSchedule()
  },
  mounted() {
    if (this.reminder.trigger) {
      this.steps = []
    }
  },
  methods: {
    ...mapMutations({
      updateSchedule: 'schedules/updateItem',
      resetSchedule: 'schedules/reset',
      updateEditType: 'schedules/updateEditType',
      updateActivity: 'clients/updateActivity',
      updateReminder: 'schedules/updateReminder'
    }),
    ...mapGetters({
      activity: 'clients/activity'
    }),
    trackPractice(data) {
      if (data.activity && this.reminder.reminderType === 'practice') {
        this.$emit('track', {
          event: 'adhere.ly:practice',
          reminderCategory: this.reminder.category,
          reminderTitle: this.reminder.title,
          activityTitle: data.activity.title
        })
      }
    },
    async createType(name) {
      // Because list type workflows are not connected to schedules uid and exercise store
      if (this.steps.includes('list') && name === 'summary') {
        this.updateReminder(this.reminder)
      }
      this.type = await this.tl.getType(name, this.schedule, this.activityId)

      // not sure this is used any longer 
      // const bgColor =
      //   this.$vuetify.theme.themes.light[this.type.controlProps.background] ||
      //   '#ffffff'

      // if (
      //   undefined ===
      //   this.$vuetify.theme.themes.light['defaultWorkflowHeaderBg']
      // ) {
      //   this.$vuetify.theme.themes.light[
      //     'defaultWorkflowHeaderBg'
      //   ] = this.$vuetify.theme.themes.light.workflowHeaderBg
      // }

      // this.$vuetify.theme.themes.light.workflowHeaderBg =
      //   this.$vuetify.theme.themes.light[this.type.controlProps.background] ||
      //   this.$vuetify.theme.themes.light['defaultWorkflowHeaderBg']

      // this.$vuetify.theme.themes.light.workflowBg = bgColor

      // this.$emit('container-width', this.type.width)
      this.key++
    },
    skip(step, inc = 1) {
      const trackToSkip = {
        name: 'name',
        channel: 'channel',
        email: 'contact',
        phone: 'contact',
        caregiverPhone: 'contact',
        caregiverEmail: 'contact'
      }

      /**
       * If the exercise is part of Activity Scheduling:
       * skip the steps 'remind2' and 'dokbot2' in the workflow
       * if an exercise preset was selected or
       * skip the step 'dokbot' in the workflow
       * if 'something else' was selected
       */
      const name = this.tl.getStep(step, this.activityId).name
      const reminderTitleTL = this.tl.reminder.title
      const reminderCategoryTL = this.tl.reminder.category
      const selection = this.type.controlProps.scope.choiceValue
      const activitySchedulingTopics = [
        'Relationships',
        'Education/Career',
        'Mind/Body/Spirituality',
        'Recreation/Interests',
        'Daily Responsibilities'
      ]
      if (
        activitySchedulingTopics.includes(reminderTitleTL) && 
        (
          selection != 'something else' && 
          (name === 'remind2' || name === 'dokbot2')
        ) || (
          selection === 'something else' && 
          name === 'dokbot'
        )
      ) {
        step += inc
      }
        /** Skip the steps 'name', 'channel', and 'contact' in any
       * workflow if a client has already been selected
       */
      Object.keys(trackToSkip).forEach(key => {
        const name = this.tl.getStep(step, this.activityId).name
        if (
          (inc < 0 || (inc > 0 && this.editType !== 'channel')) &&
          (this.schedule.id || this.clientData.clientId) &&
          (this.schedule[key] || this.clientData[key]) &&
          Object.values(trackToSkip).includes(name)
        ) {
          step += inc
        }
      })
      return step
    },
    nextStep() {
      const backToStepDefined =
        this.schedule.backToStep && this.schedule.step !== 'channel'
      const name = backToStepDefined 
        ? this.schedule.backToStep
        : this.schedule.step
      if (
        this.tl.findIndexStepByName(name, this.activityId) === 0 &&
        (name === 'date' || name === 'time')
      ) {
        return {
          name: 'summary'
        }
      }

      let step =
        this.tl.findIndexStepByName(name, this.activityId) +
        (backToStepDefined ? 0 : 1)

      step = this.skip(step)
      return this.tl.getStep(step, this.activityId)
    },
    previousStep() {
      this.steps.pop()
      return {
        name: this.steps[this.steps.length - 1]
      }
    },
    async goToPreviousStep() {
      const step = this.previousStep().name
      await this.updateSchedule({ step })
      await this.createType(step)
      if (step == 'activity') {
        this.$router.go(-1)
      } 
    },
    async goToNextStep(data) {
      if (data.list && !this.activity().uid) {
        this.updateActivity(data.list)
      }
      await this.updateSchedule(data)

      const step = this.nextStep().name
      // await new Promise(resolve => setTimeout(resolve, 1))
      await this.steps.push(step) // TODO: had to make this await
      // TODO: Trace through "next" and find out why it's firing twice
      // Possible set a flag for dokbot (if dokbot already exists, ignore)
      this.$nextTick(async () => {
        this.trackPractice(data)
        this.updateSchedule({ step }) // TODO: error
        await this.createType(step)
      })
    },
    async goToStep(data) {
      await this.updateSchedule(data)
      this.steps.push(data.step)

      await this.createType(data.step)
    },
    async close() {
      await this.$emit('track', { event: 'adhere.ly:closed' })
      this.$emit('modal-close')
      this.$router.go(-1)
    },
    resizeHandler() {
      setTimeout(() => {
        this.setBodyStyle()
      }, 100)
    },
    setBodyStyle() {
      if (!this.type.controlProps.params.previewMode) {
        const paddingTop = this.$refs.workflowHeader
          ? `${this.$refs.workflowHeader.clientHeight}px`
          : 0
  
        const paddingBottom = this.$refs.workflowFooter
          ? `${this.$refs.workflowFooter.clientHeight}px`
          : 0
  
        this.bodyStyles = { paddingTop, paddingBottom }
      }
    }
  }
}
</script>

<style lang="scss">

.workflow {
  height: 100%;
  box-shadow: none !important;
  border-radius: 0 !important;
  font-family: var(--font-family-primary);
  // overflow-y: auto;

  a {
    text-decoration: underline !important;
  }

  &.v-card {
    background: var(--v-white-base) !important;
  }

  &-header,
  &-footer {
    position: absolute;
    width: 100%;
    left: 0;
  }

  &-header {
    padding-top: 24px;
    // padding: var(--workflow-header-pt, var(--workflow-header-py))
    //   var(--workflow-header-pr, var(--workflow-header-px))
    //   var(--workflow-header-pb, var(--workflow-header-py))
    //   var(--workflow-header-pl, var(--workflow-header-px));
    @media (max-width: 600px) {
      padding-left: 12px;
      padding-right: 12px;
    }
    background: var(--v-white-base);

    .v-btn {
      border-radius: 0;
      z-index: 90;
    }
  }

  &-body-video {
    padding-top: 50px !important;
  }

  &-body-wrap {
    padding-top: 0px;
    height: 100%;
    margin-bottom: 6rem;

    // padding: var(--workflow-body-pt, var(--workflow-body-py))
    //   var(--workflow-body-pr, var(--workflow-body-px))
    //   var(--workflow-body-pb, var(--workflow-body-py))
    //   var(--workflow-body-pl, var(--workflow-body-px));
  }

  &-footer {
    bottom: 0;
    // padding: var(--workflow-footer-pt, var(--workflow-footer-py))
    //   var(--workflow-footer-pr, var(--workflow-footer-px))
    //   var(--workflow-footer-pb, var(--workflow-footer-py))
    //   var(--workflow-footer-pl, var(--workflow-footer-px));
  }
}
// .workflow-name-dokbot .workflow-body-wrap {
//   // padding: 0 var(--workflow-body-pr, var(--workflow-body-px)) 1rem
//   //   var(--workflow-body-pl, var(--workflow-body-px)) !important;
// }
</style>
