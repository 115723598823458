<template>
  <v-form @submit.prevent="next">
    <v-row>
      <slot :message="fakeMessage" />

      <v-col v-if="showConsent">
        <div class="content-block">
          <v-checkbox v-model="smsConsent">
            <template v-slot:label>
              <div>
                Client has consented to receive this text message.<br>
                (anti-spam requirement)
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://help.doxy.me/en/articles/4296864-how-to-use-doxy-me-reminders"
                      @click.stop
                      v-on="on"
                    >
                      Teleconsent info
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-col>

      <actions
        :disabled="isEditMode"
        :valid="smsConsent"
        label="Confirm"
        icon=""
      />
    </v-row>
    <v-row>
      <span 
        v-if="!nextAppointmentIsValid" 
        class="schedule-error"
      >
        {{ nextAppointmentErr }}
      </span>
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import uniqid from 'uniqid'
import format from 'string-template'
import moment from '@/plugins/moment'
import Actions from '@/kit/components/Actions'

export default {
  name: 'BaseSummary',
  components: { Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isEditMode: false,
      smsConsent: true,
      valid: false,
      nextAppointmentIsValid: true,
      nextAppointmentErr: ""
    }
  },
  computed: {
    ...mapGetters({customMessage: 'schedules/customMessage'}),
    showConsent() {
      return this.scope.smsConsentEnabled && this.scope.channel.key === 'sms'
    },
    dates() {
      return [...(this.scope.date || [])]
    },
    times() {
      return [...(this.scope.time || [])]
    },
    nextAppointmentDate() {
      return this.dateFormat(this.dates[0])
    },
    nextAppointmentTime() {
      return this.formatTimeUtcToLocal(this.times[0])
    },
    fakeMessage() {
      let message = this.message
      let id = '' + uniqid()
      id = id.slice(0, 6) //functions/src/misc/short-link.ts:length
      let link = format('{baseUrl}/s/{id}', {
        id: '{id}',
        baseUrl: process.env.VUE_APP_BASE_URL
      })
      link = format(link, { id })
      message = format(message, {
        link,
        nextAppointmentDate: this.nextAppointmentDate,
        nextAppointmentTime: this.nextAppointmentTime
      })

      return message
    },
    message() {
      let remindLowercase = null

      if (this.scope.remind) {
        remindLowercase =
          this.scope.remind.charAt(0).toLowerCase() + this.scope.remind.slice(1)
      }
      const message = this.customMessage 
        || this.params.messages[this.scope.channel.key].replace(/You/, 'you')

      const formattedMessage = format(message, {
        ...this.scope,
        ...this.params,
        link: '{link}',
        nextAppointmentDate: '{nextAppointmentDate}',
        nextAppointmentTime: '{nextAppointmentTime}',
        remindLowercase
      })

      return formattedMessage
    }
  },
  created() {
    this.$root.$on('isEditMode', (editMode) => {
      this.isEditMode = editMode
    })
  },
  mounted() {
    this.updateItem({ backToStep: null })

    if (this.showConsent) {
      this.smsConsent = this.scope.smsConsent
    }
  },
  methods: {
    ...mapMutations({
      updateItem: 'schedules/updateItem'
    }),
    next() {
      const params = {}
      const str = this.scope.providerName
      if (this.params.link !== undefined) {
        params.link = this.params.link
      }

      if (undefined === params.link && undefined !== this.scope.link) {
        params.link = this.scope.link
      }

      if (null != params.link) {
        params.link = params.link.replace(
          '{baseUrl}',
          process.env.VUE_APP_BASE_URL
        )
      }

      this.$emit('go-to-next-step', {
        message: this.message,
        ...params,
        subject: `A friendly reminder from ${str}`,
        utcOffset: moment().utcOffset(),
        smsConsent: this.smsConsent
      })
    }
  }
}
</script>

<style lang="scss">
.content-block {
  margin: 0.625rem 0 1.25rem;
  padding: 0 60px;

  .v-messages {
    display: none;
  }

  label {
    color: var(--summary-header-sms-color);
    font-size: 14px;
    font-weight: var(--summary-header-sms-font-weight);
  }
}
.schedule-error {
  color: red;
  margin-top: -24px;
}
</style>
