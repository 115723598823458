var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex relaxation-select justify-center',
    { 'relaxation-select--dense': _vm.dense }
  ]},_vm._l((_vm.items),function(item){return _c('v-card',{key:item.value,class:[
      'd-flex align-center justify-center relaxation-select-item',
      { active: _vm.isSelected === item.value }
    ],on:{"click":function($event){return _vm.$emit('action-selected', item.value)}}},[_c('img',{attrs:{"src":item.img,"alt":item.title}})])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }