<template>
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.76877 17.7741C9.46046 18.0753 8.96059 18.0753 8.65228 17.7741L0.231232 9.54548C-0.0770761 9.24422 -0.0770761 8.75578 0.231233 8.45452L8.65229 0.225944C8.96059 -0.0753156 9.46046 -0.0753155 9.76877 0.225944C10.0771 0.527208 10.0771 1.01565 9.76877 1.31691L1.90596 9L9.76877 16.6831C10.0771 16.9844 10.0771 17.4728 9.76877 17.7741Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-primary-base)'
    }
  }
}
</script>
