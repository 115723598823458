<template>
  <v-row
    v-if="activityTitle === 'Deep breathing' && isPreviewMode"
    class="preview-component-adjusted"
  >
    <v-col class="col-12 py-0">
      <div class="preview-component-body mx-auto">
        <div class="preview-component-body-helper">
          <div
            class="preview-component-body-image"
            :style="{ backgroundImage: `url(${previewDevice})` }"
          />
          <div class="preview-component-wrapper-deep-breathing">
            <div class="preview-component-wrapper-helper">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row
    v-else
    class="preview-component"
  >
    <v-col class="col-12 py-0">
      <div class="preview-component-body mx-auto">
        <div class="preview-component-body-helper">
          <div
            class="preview-component-body-image"
            :style="{ backgroundImage: `url(${previewDevice})` }"
          />
          <div class="preview-component-wrapper">
            <div class="preview-component-wrapper-helper">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import previewDevice from '@/assets/img/preview-device.png'
import { mapGetters } from 'vuex';

export default {
  name: 'PreviewComponent',
  data() {
    return {
      previewDevice
    }
  },
  computed: {
    ...mapGetters({
      activityTitle: 'ux/activityTitle',
      isPreviewMode: 'ux/isPreviewMode',
      reminderType: 'ux/reminderType'
    })
  }
}
</script>

<style lang="scss" scoped>
.preview-component {

  margin-top: 6vh;

  @media (max-height: 860px) {
    margin-top: 0px;
  }

  &-adjusted {
    margin-top: 2rem;
  }

  /* TODO: This is where the new UX store comes in
  make margin-top 2rem if a deep breaking preview is rendered */
  &-body {

    // general width and max-width of iphone and dokbot preview
    width: 100%;
    max-width: 312px; // I found that commenting out the max width does nothing for narrow modal

    @media (max-height: 740px) {
      width: 100%;
      max-width: 276px;
    }

    // these attributes may address next and back 
    // button overlapping iPhone preview, if it comes up
    // padding-bottom: 50px;
    // padding-top: 50px;

    @media (max-width: 379px) {
      max-width: 256px;
    }

    // size attributes for dokbot relative to iPhone image
    &-helper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 185.76%;
    }

    // size attributes for iPhone image
    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
    }
  }

  // size attributes for dokbot
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //previous padding: 77px 18.5px 55px;
    //previous padding: 74px 18.5px 53.3px;
    //previous padding: 82px 17.5px 57.3px;
    //previous padding: 87px 18.5px 52.3px;

    // padding configuration for dokbot element,
    // can be used to adjust visibility of footer
    padding: 75px 18.5px 51.3px;
    @media (max-width: 379px) {
      padding: 61px 15px 43px;
    }
    /* top horizontal bottom */
    @media (max-height: 860px) {
      padding: 67px 18.5px 47.3px;
    }

    &-deep-breathing {
      @media (max-height: 710px) {
        padding: 115px 18.5px 47.3px;
      }

      @media (max-height: 710px), (max-width: 959px) {
        padding: 12px 18.5px 47.3px;
      }
    }

    &-helper {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      scrollbar-width: thin;
      // overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
