
// Helper class for Audio component
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio
// Props: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement

export default class AudioPlayer {

  clockString = 'Loading'
  audioPlayer = null
  updateHandler = null

  constructor(audioSource) {
    this.audioPlayer = new Audio()

    // Autoplay isn't guaranteed anyway, but iOS especially doesn't do it
    this.audioPlayer.autoplay = false
    this.audioPlayer.preload = 'metadata'
    this.audioPlayer.src = audioSource

    this.audioPlayer.load()

    // Triggers every time the media progresses
    this.updateHandler = () => {
      if (this.getTimeRemaining() >= Infinity) return

      const minutes = Math.floor(this.getTimeRemaining() / 60)
      const seconds = this.getTimeRemaining() % 60

      if (isNaN(minutes) || isNaN(seconds))
        return this.clockString = 'Loading'

      this.clockString = minutes + ':' + (seconds <= 9 ? '0' + seconds : seconds)
    }

    // Update the clock when the media is ready
    // If you call updateHandler sooner you will get undefined for duration etc.
    this.once('loadedmetadata', () => {
      // Server MUST send Content-range header!
      // https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity
      this.updateHandler()
    })
    this.once('loadeddata', () => {
      this.updateHandler()
    })

    this.on('timeupdate', this.updateHandler)
  }

  /**
   * Clean up the audio component/unload
   * Call this before leaving a page, with beforeUnmount (Vue3 compatible)
   */
 destroy() {
    // Stop the audio before leaving, else it will continue to play
    // (it does not get gc'd until paused/finished even when unref'd)
    this.stop()
    this.off(this.updateHandler)
  }

  async play() {
    await this.audioPlayer.play()
  }
  async pause() {
    await this.audioPlayer.pause()
  }
  async stop() {
    await this.audioPlayer.pause()
  }

  /**
   * @returns {string} Auto generated clock string using media data. The value updates live, call this method whenever you want a refresh.
   */
  getClockString() {
    return this.clockString
  }

  /**
   * @returns {number} Time passed in seconds as an integer
   */
  getTimePassed() {
    return Math.round(this.audioPlayer.currentTime) // Typically float
  }

  /**
   * @returns {number} Time remaining in seconds as an interger
   */
  getTimeRemaining() {
    return Math.round(this.audioPlayer.duration - this.audioPlayer.currentTime) // Typically float
  }

  /**
   * @returns {number} Total time in seconds of the media
   */
  getDuration() {
    return Math.round(this.audioPlayer.duration)
  }
  
  // Nodejs style handler register helpers
  // Remember, callback must always be the same function reference
  
  /**
   * Add an event listener to the audio player
   * @param eventName - The name of the event you want to listen for.
   * @param callback - The function to be called when the event is fired.
   */
  on(eventName, callback) {
    this.audioPlayer.addEventListener(eventName, callback)
  }

  /**
   * It removes an event listener from the audio player
   * @param {string} eventName - The name of the event you want to listen for
   * @param {function} callback - The function reference that you want to remove from the listener
   */
  off(eventName, callback) {
    this.audioPlayer.removeEventListener(eventName, callback)
  }

  /**
   * Triggers a callback when an event happens only once, then removes the listener
   * @param {string} eventName Name of event to listen to
   * @param {function} callback Function to call when event is triggered
   */
  once(eventName, callback) {
    // Must be an arrow function to access *this*
    const eventHandler = () => {
      callback()
      this.off(eventName, callback)
    }
    this.on(eventName, eventHandler)
  }
}