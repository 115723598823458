import format from 'string-template'
import ControlChoice from '@/kit/controls/Choice'

export default (scope, params) => {
  return {
    name: 'choice',
    controlComponent: ControlChoice,
    controlProps: {
      scope,
      params,
      property: 'choice',
      title: format(params.title, scope),
      items: params.items,
      callback: value => ({
        choice: value,
        choiceName: value.title,
        choiceValue: value.value
      })
    }
  }
}
