import ControlTimePicker from '@/kit/controls/TimePicker'

export default (scope, params) => {
  return {
    name: 'time',
    controlComponent: ControlTimePicker,
    controlProps: {
      scope,
      params,
      property: 'time',
      title:
        scope.activity.notificationConfig.type === 'appointment'
          ? "What time(s) for your client's next appointment?"
          : 'What time(s) should your client be reminded?',
      pickerTitle: 'What time should your client be reminded?'
    }
  }
}
