import format from 'string-template'
import ControlInputText from '@/kit/controls/InputText'

export default (scope, params) => {
  return {
    name: 'remind2',
    controlComponent: ControlInputText,
    controlProps: {
      scope,
      params,
      property: 'remind2',
      background: params.background || 'secondary',
      title: format(params.title, {
        name: scope.clientName,
        caregiverName: scope.caregiverName,
        choiceValue: scope.choiceValue,
        remind: scope.remind
      }),
      placeholder: format(params.placeholder, {
        choiceExample: scope.choiceExample,
        choiceExample2: scope.choiceExample2
      }),
      rules: [
        v => !!v || 'Value is required',
        v => v.length <= 50 || 'Max 50 characters'
      ],
      maxLength: 50,
      counter: 50,
      privacy: true,
      track: true
    }
  }
}
