<template>
  <v-form
    :class="['input-radio', { 'input-radio-full-width': params.fullWidth }]"
    @submit.prevent="next"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>
      <v-col :class="params.fullWidth ? 'col-sm-10' : 'col-sm-4'">
        <v-radio-group v-model="value">
          <v-radio
            v-for="item in params.items"
            :key="item.id"
            :value="item"
          >
            <template v-slot:label>
              <div
                class="input-radio-label h4"
                v-html="item.text"
              />
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>

      <actions
        :valid="!!value.id"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import Actions from '@/kit/components/Actions'
import { mapActions } from 'vuex'

export default {
  components: { Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: {}
    }
  },
  beforeDestroy() {
    this.resetUXState()
  },
  mounted() {
    this.value = this.scope[this.property] || {}
  },
  methods: {
    ...mapActions({
      resetUXState: 'ux/resetUXState'
    }),
    async next() {
      await this.$emit('track', {
        event: `adhere.ly:${this.property}:selected`,
        value: this.value
      })

      const data = {
        [this.property]: this.value,
        [this.property + 'Name']: this.value.text,
        link: this.value.link || ''
      }

      if (this.value.dokbotParams) {
        data.dokbotParams = this.value.dokbotParams
      }

      await this.$emit('go-to-next-step', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-radio {
  &-label {
    font-weight: 400;
  }
  &-full-width {
    .input-radio-label {
      width: 100%;
      padding: 1.25rem 1.5rem;
      border-radius: var(--card-border-radius) var(--card-border-radius) 0
        var(--card-border-radius);
      background-color: var(--v-neutralLight-base);
    }
  }
}
</style>
