export default class TypeLoader {
  constructor(reminder) {
    this.reminder = reminder
    this.loadedTypes = {}
  }
  // workflow(activityId = null) {
    workflow() {
    return this.reminder.workflow || []
    // if (activityId) {
    //   const activity = this.reminder.items.find(item => item.id === activityId)

    //   if (activity) {
    //     return activity.workflow || []
    //   }
    // }

    // return []
  }
  async getType(name, scope, activityId) {
    if (!this.loadedTypes[name]) {
      await this.loadType(name)
    }
    const step = this.getStep(
      this.findIndexStepByName(name, activityId),
      activityId
    )

    return this.loadedTypes[name].default(scope, {
      ...step.params,
      reminder: this.reminder
    })
  }
  async loadType(name) {
    this.loadedTypes[name] = await require('@/kit/types/' + name)

    return this.loadedTypes[name]
  }
  getStep(index, activityId = null) {
    return this.workflow(activityId)[index] || { name: 'activity' }
  }
  findIndexStepByName(name, activityId = null, def = 0) {
    let index = def

    this.workflow(activityId).some((type, i) => {
      if (type.name === name) {
        index = i
        return true
      }
    })

    return index
  }
}