<template>
  <base-summary
    v-slot="slotProps"
    :scope="scope"
    :params="params"
    :property="property"
    v-on="$listeners"
  >
    <v-col class="col-12">
      <summary-title
        :scope="scope"
        :params="params"
        :slot-props="slotProps"
        v-on="$listeners"
      />
    </v-col>

    <v-col class="col-12">
      <v-row
        no-gutters
        justify="center"
      >
        <v-col class="col-12 card-width">
          <div class="summary-content">
            <v-row class="flex-column">
              <v-col class="col-12 pt-0">
                <summary-headers
                  :scope="scope"
                  :dates="dates"
                  :times="times"
                />
              </v-col>
              <v-col class="col-12 pb-0">
                <div class="editor">
                  <v-textarea
                    :readonly="!isEditMode"
                    :value="setInitialValue(slotProps.message)"
                    :label="isEditMode ? 'Edit mode' : ''"
                    rows="3"
                    @change="finalizeMessage"
                  />
                </div>
                <v-btn
                  :disabled="!isAdminOrHasSubscription"
                  variant="outlined"
                  size="small"
                  class="edit-button elevation-0"
                  color="primary"
                  @click="isEditMode = !isEditMode"
                >
                  {{ !isEditMode ? 'Edit message' : 'Stop editing' }}
                </v-btn>
                <v-tooltip
                  v-if="!isAdminOrHasSubscription"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ mdiInformation }}
                    </v-icon>
                  </template>
                  <span>Upgrade to PRO to activate custom message editing</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </base-summary>
</template>

<script>
import BaseSummary from '@/kit/controls/BaseSummary'
import SummaryTitle from '@/kit/components/SummaryTitle'
import SummaryHeaders from '@/kit/components/SummaryHeaders'
import { mdiPencil, mdiClose, mdiInformation } from '@mdi/js'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  components: { SummaryTitle, BaseSummary, SummaryHeaders },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      customMessage: {
        type: String,
        default: ''
      },
      customMessageLink: '',
      isEditMode: false,
      mdiClose,
      mdiInformation,
      mdiPencil,
    }
  },
  computed: {
    ...mapGetters({ 
      user: 'authentication/user',
      hasActiveSubscription: 'subscription/hasActiveSubscription'
    }),
    dates() {
      return [...(this.scope.date || [])]
    },
    isAdminOrHasSubscription() {
      return this.user.role === 'admin' || this.hasActiveSubscription
    },
    times() {
      return [...(this.scope.time || [])]
    }
  },
  watch: {
    isEditMode(isEdit) {
      this.$root.$emit('isEditMode', isEdit)
    }
  },
  mounted() {
    this.customMessage = this.clean(this.stripUrl(this.params.messages.sms))
  },

  methods: {
    ...mapMutations('schedules', ['setCustomMessage']),
    // finalizeMessage(content) {
    //   if (content.indexOf('{link}') === -1) {
    //     content += ' {link}'
    //   }
    //   this.setCustomMessage(content)
    // },
    finalizeMessage(content) {
      this.setCustomMessage(content + ' ' + this.customMessageLink)
    },
    /**
     * 
     * @param {String} str 
     * strips out the url from the sample
     * sms text to be sent for activities because it's 
     * confusing to the user to think they can follow that link
     * and complete the exercies before it's sent to the client
     */
     stripUrl(str) {
      const linkIndex = str.search('Click here')
      this.customMessageLink = linkIndex !== -1 ? 
        'Click here to start: {link}' : this.customMessageLink
      const strlen = linkIndex !== -1 ? linkIndex : str.length
      return str.slice(0, strlen)     
    },
     // replace spaces and <br>
    clean(html) {
      return html.replace(/\s\s+/g, ' ').replace(/<br\s*\/?>/mg, '')
    },
    setInitialValue(str) {
      const msg = this.clean(this.stripUrl(str))
      // replace upper case You so as not to be displayed in
      // the messages as ...remind you that You are...
      this.customMessage = msg.replace(/You/, 'you')
      return this.customMessage
    }
  }
}
</script>

<style scoped lang="scss">

.card-width {
  max-width: 480px;
}
.edit-button {
  margin-right: 1rem;
}
.editor {
  display: flex;
  flex-direction: column;
}
.summary-content {
  padding: var(--summary-sms-content-padding);
  border: var(--summary-content-border);
}
</style>
