<template>
  <v-autocomplete
    :no-filter="true"
    :value="value"
    class="custom-text-field custom-text-field-autocomplete"
    :items="items"
    :search-input.sync="search"
    :height="height"
    hide-no-data
    hide-selected
    outlined
    background-color="#ffffff"
    :rules="rules"
    :item-text="itemText"
    :item-value="itemValue"
    :placeholder="placeholder"
    :return-object="returnObject"
    :autofocus="autofocus"
    :validate-on-blur="validateOnBlur"
    :menu-props="{ offsetY: true, contentClass: 'custom-select-menu' }"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FormAutocompleteName',
  props: {
    value: {
      type: [Object, String]
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '64'
    },
    rules: {
      type: Array,
      default: () => []
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    },
    findFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      search: null,
      clients: [],
      res: []
    }
  },
  computed: {
    items: {
      get: function() {
        const items = [...this.res]
        if (undefined !== this.value && undefined !== this.value.id) {
          items.push({ id: this.value.id, name: this.value.name })
        }

        return items
      },
      set: function(newValue) {
        this.res = newValue
      }
    }
  },
  async created() {
    this.clients = await this.fetchClients()
    this.$watch('search', this.fetchData)
  },
  methods: {
    ...mapActions('clients', ['fetchClients']),
    fetchData: function(val) {
      this.$emit('search', val)
      if (this.isBlank(val)) {
        this.items = []
      } else {
        this.items = this.clients.filter(c => {
          const where = [c.email, c.name, c.phone].join('').toLowerCase()
          return where.indexOf(val.toLowerCase()) !== -1
        })
      }
    },
    isBlank(str) {
      return !str || '' === str
    }
  }
}
</script>
