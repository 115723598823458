<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.48935 0.516611C7.48939 0.443099 7.47387 0.370427 7.44381 0.303454C7.41376 0.236481 7.36987 0.176751 7.31508 0.128261C7.2603 0.0797705 7.19587 0.0436369 7.12612 0.022277C7.05637 0.000917047 6.9829 -0.00517673 6.91063 0.00440324C4.8388 0.287521 2.96182 1.38473 1.68851 3.05705C1.64503 3.11505 1.61421 3.18167 1.59807 3.25254C1.58192 3.32341 1.58082 3.39691 1.59484 3.46824C1.60885 3.53956 1.63767 3.60709 1.6794 3.66639C1.72112 3.72569 1.77481 3.77542 1.83694 3.81232L4.83268 5.55629C4.91025 5.60159 4.99836 5.62528 5.08799 5.62496C5.15596 5.62499 5.22324 5.61135 5.28591 5.58482C5.34857 5.55829 5.40536 5.51941 5.45293 5.47047C5.90576 5.00176 6.47762 4.66757 7.10603 4.50441C7.21598 4.47615 7.31345 4.41172 7.383 4.32131C7.45255 4.2309 7.49021 4.11968 7.49003 4.00525L7.48935 0.516611Z"
      fill="currentColor"
    />
    <path
      d="M8.6856 0.126373C8.63058 0.17477 8.58649 0.234502 8.55631 0.301541C8.52612 0.368579 8.51054 0.441366 8.51062 0.514992V4.00844C8.51052 4.12274 8.54813 4.23384 8.61753 4.32421C8.68693 4.41458 8.78417 4.4791 8.89394 4.5076C9.52259 4.67064 10.0947 5.00484 10.5477 5.47366C10.627 5.55535 10.731 5.60807 10.8434 5.62349C10.9557 5.63891 11.0699 5.61614 11.168 5.5588L14.1637 3.8155C14.2261 3.77852 14.2801 3.72859 14.3219 3.66901C14.3638 3.60943 14.3926 3.54156 14.4065 3.4699C14.4204 3.39824 14.4191 3.32442 14.4026 3.25332C14.3861 3.18223 14.3548 3.11548 14.3108 3.05749C13.0379 1.38504 11.161 0.287775 9.08934 0.00484404C9.01734 -0.00521345 8.94404 0.000431393 8.87439 0.0213972C8.80473 0.042363 8.74035 0.0781613 8.6856 0.126373Z"
      fill="currentColor"
    />
    <path
      d="M14.9352 11.3649C14.9914 11.3649 15.0473 11.3557 15.1006 11.3374C15.1697 11.3137 15.2328 11.2752 15.2858 11.2246C15.3388 11.174 15.3803 11.1125 15.4077 11.0443C16.1975 9.09447 16.1975 6.91006 15.4077 4.96026C15.3805 4.89185 15.339 4.83013 15.2861 4.77925C15.2332 4.72837 15.1701 4.68952 15.1011 4.66531C15.032 4.64111 14.9587 4.63211 14.8859 4.63893C14.8131 4.64575 14.7426 4.66822 14.6792 4.70484L11.6834 6.45362C11.5849 6.51082 11.5082 6.59962 11.4655 6.70588C11.4228 6.81214 11.4166 6.92975 11.4479 7.03998C11.6212 7.66656 11.6212 8.32904 11.4479 8.95561C11.4167 9.06585 11.423 9.18341 11.4657 9.28965C11.5083 9.39588 11.585 9.48469 11.6834 9.54197L14.6792 11.2908C14.7565 11.338 14.8448 11.3636 14.9352 11.3649Z"
      fill="currentColor"
    />
    <path
      d="M9.0213 15.9993C9.04408 15.9994 9.06683 15.9978 9.08938 15.9945C11.16 15.7124 13.0362 14.6167 14.3095 12.946C14.3541 12.8879 14.3859 12.8208 14.4027 12.7493C14.4195 12.6777 14.4209 12.6034 14.4069 12.5313C14.3928 12.4591 14.3636 12.3909 14.3212 12.3311C14.2788 12.2713 14.2242 12.2213 14.161 12.1846L11.1653 10.4406C11.0672 10.3831 10.9529 10.3603 10.8405 10.3759C10.7281 10.3914 10.6241 10.4444 10.545 10.5264C10.0917 10.9946 9.51967 11.3285 8.89125 11.4918C8.78149 11.5203 8.68424 11.5848 8.61484 11.6752C8.54544 11.7656 8.50783 11.8766 8.50793 11.9909V15.4851C8.50802 15.5527 8.52132 15.6197 8.54706 15.6821C8.57281 15.7445 8.6105 15.8013 8.65798 15.849C8.70546 15.8968 8.7618 15.9346 8.82379 15.9604C8.88578 15.9862 8.95219 15.9994 9.01925 15.9993H9.0213Z"
      fill="currentColor"
    />
    <path
      d="M6.9107 15.9948C6.93325 15.998 6.956 15.9996 6.97878 15.9996C7.04587 15.9997 7.11231 15.9864 7.1743 15.9606C7.23629 15.9347 7.29262 15.8968 7.34006 15.849C7.38749 15.8011 7.4251 15.7443 7.45073 15.6818C7.47636 15.6193 7.48951 15.5523 7.48942 15.4846V11.9905C7.48933 11.8762 7.45155 11.7652 7.38203 11.675C7.31251 11.5847 7.2152 11.5204 7.10542 11.492C6.47721 11.3284 5.90547 10.9943 5.45232 10.526C5.37315 10.4441 5.2691 10.3912 5.15672 10.3758C5.04433 10.3604 4.93006 10.3833 4.83206 10.4408L1.83632 12.1841C1.77351 12.2211 1.71922 12.2711 1.67709 12.3309C1.63495 12.3907 1.60592 12.4589 1.59194 12.5308C1.57796 12.6028 1.57935 12.677 1.59602 12.7484C1.61268 12.8198 1.64423 12.8868 1.68858 12.9449C2.96243 14.6161 4.83932 15.7123 6.9107 15.9948V15.9948Z"
      fill="currentColor"
    />
    <path
      d="M0.900095 11.3311C0.953028 11.3499 1.00875 11.3594 1.06486 11.3593C1.1547 11.3595 1.24301 11.3358 1.32086 11.2906L4.3166 9.54182C4.41513 9.48462 4.49185 9.39582 4.53454 9.28956C4.57724 9.1833 4.58344 9.06569 4.55217 8.95546C4.37879 8.32888 4.37879 7.6664 4.55217 7.03983C4.58421 6.93025 4.57912 6.81304 4.53768 6.70671C4.49625 6.60039 4.42085 6.51104 4.32341 6.45278L1.32086 4.70262C1.25776 4.66555 1.18749 4.6426 1.11482 4.63532C1.04215 4.62805 0.968781 4.63662 0.899685 4.66045C0.830588 4.68429 0.767383 4.72283 0.714356 4.77347C0.661328 4.8241 0.61972 4.88564 0.592351 4.95392C-0.19745 6.90372 -0.19745 9.08813 0.592351 11.0379C0.619681 11.1063 0.661328 11.1678 0.714443 11.2184C0.767558 11.269 0.830887 11.3075 0.900095 11.3311V11.3311Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    name: "ActivityIcon"
  }
</script>