<template>
  <v-row
    :class="[
      'exercise-image no-gutters',
      { 'exercise-image--dense': dense }
    ]"
  >
    <v-col cols="12">
      <div class="d-flex justify-center">
        <div
          :class="['image-wrap', { clickable: !isAudioFinished }]"
          @click="togglePlay"
        >
          <div class="image-wrap-helper">
            <div class="image">
              <img 
                :src="getImageUrl(imageFile)"
              >
              <div
                v-if="!isAudioFinished"
                class="control-button"
              >
                <icon-play-filled
                  v-if="!isAudioPlaying"
                />
                <icon-pause-filled
                  v-else 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col
      v-if="!isAudioFinished"
      class="col-12 exercise-timer text-center"
    >
      {{ clockString }}
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import IconPlayFilled from '@/components/icons/IconPlayFilled'
import IconPauseFilled from '@/components/icons/IconPauseFilled'

import AudioPlayer from '@/kit/helpers/AudioPlayer'

export default {
  name: 'AudioPlayer',
  components: { IconPlayFilled, IconPauseFilled },
  props: {
    audioFile: {
      type: String,
      required: true
    },
    imageFile: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Don't seem to be used, but might be referenced by parent:
      completeOn: 70,
      sessionRef: null,
      notificationId: this.timeId,  // undefined
      //
      audioPlayer: null,
      isAudioPlaying: false,
      isAudioFinished: false,
      trackIntervalId: null,
      clockIntervalId: null,
      clockString: 'Loading',
    }
  },
  created() {
    this.audioPlayer = new AudioPlayer('/media/' + this.audioFile)

    this.audioPlayer.once('ended', () => {
      this.isAudioFinished = true

      this.clear()
    })

    this.clockIntervalId = setInterval(() => {
      this.clockString = this.audioPlayer.getClockString()
    }, 100)
  },
  beforeDestroy() {
    this.audioPlayer.destroy()
    this.clear()
  },
  methods: {
    ...mapActions('schedules', ['sessionProgress']),
    togglePlay() {
      // Cancel event if the player is stopped
      if (this.isAudioFinished) return false
      if (this.audioPlayer.getTimeRemaining() < 1) return false

      // Toggle
      this.isAudioPlaying = !this.isAudioPlaying

      // Look at YoutubePlayer.vue for interval
      // function reference for youtube videos
      if (this.isAudioPlaying) {
        // Audio has just started playing
        this.audioPlayer.play()

        if (!this.trackIntervalId) {
          this.updateSession()
          this.trackIntervalId = setInterval(this.updateSession, 3000)
        }
      } else {
        // Audio has stopped playing
        this.audioPlayer.stop()

        clearInterval(this.trackIntervalId)
        this.trackIntervalId = null
      }
    },
    async updateSession() {
      const $event = {
        duration: this.audioPlayer.getDuration(),
        timePassed: this.audioPlayer.getTimePassed()
      }
      this.$emit('stats', $event)
      await this.sessionProgress([this, $event])
    },
    clear() {
      this.updateSession()
      clearInterval(this.clockIntervalId)
      clearInterval(this.trackIntervalId)
    },
    getImageUrl(file) {
      return require('@/assets/img/' + file)
    }
  }
}
</script>

<style lang="scss" scoped>
.exercise-image {

  .image-wrap {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  
    &.clickable {
      cursor: pointer;
    }

    &-helper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .image {
      // position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    
      img {
        display: block;
        max-width: 45%;
        width: 100%;
        height: auto;
      }
    }
  }

  .control-button {
    display: block;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100px;
    height: 80px;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 599px) {
      width: 90px;
      height: 90px;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .exercise {
    &-timer {
      font-size: 48px;
      font-weight: 300;
      color: var(--v-neutralMedium-base);
      line-height: 1;

      @media (max-width: 959px) {
        font-size: 42px;
      }

      @media (max-width: 599px) {
        font-size: 28px;
      }
    }
  }

  &--dense {
    .control-button {
      width: 80px;
      height: 50px;
    }

    .exercise {
      &-timer {
        font-size: 28px;
        font-weight: 300;
        color: var(--v-neutralMedium-base);
        line-height: 1;
      }
    }
  }
}
</style>
