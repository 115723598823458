var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:[
    'relaxation-balloon no-gutters',
    { 'relaxation-balloon--dense': _vm.dense }
  ]},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{class:['image-wrap', { clickable: _vm.timeLeft }],on:{"click":_vm.click}},[_c('div',{staticClass:"image-wrap-helper"},[_c('div',{staticClass:"balloon",style:({
              animationDuration: _vm.animationDuration + 's',
              animationPlayState: _vm.animationPlayState
            })},[_c('img',{attrs:{"src":_vm.balloon,"alt":"deep breathing exercise"}}),(_vm.timeLeft)?_c('div',{staticClass:"control-button"},[(!_vm.animation)?_c('icon-play'):_c('icon-pause')],1):_vm._e()])])])])]),_c('v-col',{staticClass:"col-12 relaxation-timer text-center"},[_vm._v(" "+_vm._s(_vm.clock)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }