<template>
  <div class="youtube-container">
    <overlay
      v-if="!videoLoaded"
      color="white"
    />

    <div
      :class="[
        'youtube-wrap',
        {
          mdOnly: $vuetify.breakpoint.mdOnly,
          smAndDown: $vuetify.breakpoint.smAndDown
        }
      ]"
    >
      <youtube
        :video-id="videoId"
        :player-vars="playerVars"
        resize
        fit-parent
        @ready="ready"
        @playing="playing"
        @paused="stopped"
        @ended="stopped"
      />
    </div>
  </div>
</template>

<script>
import Overlay from '@/components/Overlay'

export default {
  name: 'YoutubePlayer',
  components: { Overlay },
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      videoLoaded: false,
      intervalId: null,
      duration: null,
      timePassed: 0,
      playerVars: {
        fs: 0,
        rel: 0,
        showinfo: 0,
        disablekb: 1,
        modestbranding: 1,
        iv_load_policy: 3,
        origin: process.env.VUE_APP_BASE_URL
      }
    }
  },
  beforeDestroy() {
    this.stopped()
  },
  methods: {
    ready(e) {
      this.duration = e.getDuration()
      this.videoLoaded = true
    },
    playing() {
      this.intervalId = setInterval(() => {
        this.timePassed++
      }, 1000)

      this.statIntervalId = setInterval(() => {
        this.emitStats()
      }, 3000)
    },
    stopped() {
      clearInterval(this.intervalId)
      clearInterval(this.statIntervalId)
      this.emitStats()
    },
    emitStats() {
      this.$emit('stats', {
        timePassed: this.timePassed,
        duration: this.duration
      })
    }
  }
}
</script>

<style lang="scss">
.youtube-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  .youtube-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
