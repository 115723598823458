import DemoComplete from '@/kit/controls/DemoComplete'

export default (scope, params) => {
  return {
    name: 'demo-complete',
    controlComponent: DemoComplete,
    controlProps: {
      scope,
      params,
      showPrevButton: false
    }
  }
}
