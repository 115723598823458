import format from 'string-template'
import ControlChoice from '@/kit/controls/Choice'
import dateTime from '@/kit/helpers/generators/dateTime'

export default (scope, params) => {
  
  return {
    name: 'activity',
    controlComponent: ControlChoice,
    controlProps: {
      scope,
      params,
      property: 'activity',
      title: params.reminder.subtitle ? format(params.reminder.subtitle, {
        name: scope.clientName,
        caregiverName: scope.caregiverName
      }) : '',
      items: params.reminder.items,
      showPrevButton: false,
      callback: value => ({
        ...dateTime(value),
        activity: value,
        reminderTitle: params.reminder.title,
        reminderCategory: params.reminder.category,
        activityName: value.title.toLowerCase()
      })
    }
  }
}
