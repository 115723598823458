<template>
  <preview-component>
    <div class="preview-relaxation-component">
      <div
        v-if="$slots.logo"
        class="preview-relaxation-component-logo mx-auto"
      >
        <slot name="logo" />
      </div>

      <div
        v-if="$slots.body"
        class="preview-relaxation-component-body"
      >
        <slot name="body" />
      </div>

      <div class="preview-relaxation-component-bottom">
        <slot />
      </div>
    </div>
  </preview-component>
</template>

<script>
import PreviewComponent from '@/components/PreviewComponent'

export default {
  name: 'PreviewRelaxationComponent',
  components: { PreviewComponent }
}
</script>

<style lang="scss" scoped>
.preview-relaxation-component {
  padding: 10px;

  @media (max-width: 379px) {
    padding-left: 6px;
    padding-right: 6px;
  }

  &-logo {
    width: 36px;

    @media (max-width: 479px) {
      width: 32px;
    }
  }

  &-body {
    margin-top: 14px;
  }

  &-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 14px;
  }
}
</style>

<style lang="scss">
.preview-relaxation-component-logo img {
  display: block;
  width: 100%;
}
</style>
