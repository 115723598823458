<template>
  <v-row
    :class="['relaxation-ball no-gutters', { 'relaxation-ball--dense': dense }]"
  >
    <v-col cols="12">
      <div class="d-flex justify-center">
        <div
          :class="['image-wrap', { clickable: !animation }]"
          @click="click"
        >
          <div class="image-wrap-helper">
            <div
              :class="['ball', classState]"
              :style="{
                backgroundColor: backgroundState,
                animationDuration: durationState
              }"
            >
              <template v-if="timeLeft">
                <template v-if="!animation">
                  <div class="ball-button">
                    <icon-play color="var(--v-orange-darken1)" />
                  </div>
                </template>
                <template v-else>
                  <div class="ball-text">
                    <div class="display-1">
                      {{ titleState }}
                    </div>
                    <div class="ball-timer">
                      {{ timer }}
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="col-12 relaxation-timer text-center">
      {{ clock }}
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import IconPlay from '@/components/icons/IconPlay'

/**
 * Although this audio player generic has built-in session progress code,
 * is it not used for deep breathing and is intended to allow audio
 * playback to work using the current system.
 * 
 * Old: DeepBreathing from kit/helpers/deepBreathing
 * New: AudioPlayer from kit/helpers/AudioPlayer
 *      this.audioPlayer = new AudioPlayer('/media/deep-breathing.mp3')
 * 
 * Old: this.playerState = 'play'
 * New: this.audioPlayer.play()
 */
import AudioPlayer from '@/kit/helpers/AudioPlayer'

export default {
  name: 'RelaxationBall',
  components: { IconPlay },
  props: {
    duration: {
      type: Number,
      required: false,
      default: 300
    },
    session: {
      type: Object,
      required: false,
      default: () => null
    },
    timeId: {
      type: String,
      required: false,
      default: () => null
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      completeOn: 70,
      notificationId: this.timeId,
      animation: false,
      playerState: 'stop',
      animationPlayState: 'paused',
      timeLeft: 0,
      intervalId: null,
      timePassed: 0,
      trip: ['breath_in', 'hold_on', 'breath_out', 'hold_out'],
      breath: {
        breath_in: {
          title: 'Breath in',
          className: 'breath-in',
          duration: 5,
          color: 'var(--v-orange-base)'
        },
        breath_out: {
          title: 'Breath out',
          className: 'breath-out',
          duration: 5,
          color: 'var(--v-orange-base)'
        },
        hold_on: {
          title: 'Hold',
          className: 'hold-on',
          duration: 5,
          color: 'var(--v-pink-base)'
        },
        hold_out: {
          title: 'Hold',
          className: 'hold-out',
          duration: 5,
          color: 'var(--v-pink-base)'
        }
      },
      audioPlayer: null,
    }
  },
  computed: {
    clock() {
      const minutes = Math.floor(this.timeLeft / 60)
      const seconds = this.timeLeft % 60

      return minutes + ':' + (seconds <= 9 ? '0' + seconds : seconds)
    },
    classState() {
      return this.animation ? this.breath[this.step].className : ''
    },
    durationState() {
      return `${this.breath[this.step].duration}s`
    },
    titleState() {
      return this.animation ? this.breath[this.step].title : null
    },
    backgroundState() {
      return this.animation
        ? this.breath[this.step].color
        : 'var(--v-orange-base)'
    },
    durationCycle() {
      let time = 0

      this.trip.forEach(item => (time += this.breath[item].duration))

      return time
    },
    remainder() {
      return this.timePassed + 1 > this.durationCycle
        ? this.timePassed % this.durationCycle
        : this.timePassed
    },
    index() {
      let sum = 0
      let step = 0

      this.trip.forEach(item => {
        sum += this.breath[item].duration

        if (this.remainder + 1 > sum) {
          step++
        }
      })

      if (step >= this.trip.length) {
        step = 0
      }

      return step
    },
    step() {
      return this.trip[this.index]
    },
    timer() {
      let total = 0

      for (let i = 0; i < this.index; i++) {
        total += this.breath[this.trip[i]].duration
      }

      return this.breath[this.step].duration + total - this.remainder - 1
    }
  },
  created() {
    this.timeLeft = this.duration
    this.audioPlayer = new AudioPlayer('/media/deep-breathing.mp3')
  },
  beforeDestroy() {
    this.audioPlayer.stop()
    this.clear()
  },
  methods: {
    ...mapActions('schedules', ['sessionProgress']),
    click() {
      if (this.timeLeft < 1 || this.animation) {
        return false
      }

      this.animation = !this.animation

      if (this.animation) {
        this.intervalId = setInterval(() => {
          this.timePassed++

          if (this.timeLeft-- <= 1) {
            this.animation = false
            this.clear()
            this.audioPlayer.stop()
            this.animationPlayState = 'paused'
          }
        }, 1000)

        this.trackIntervalId = setInterval(this.updateSession, 3000)
        this.audioPlayer.play()
        this.animationPlayState = 'running'
      } else {
        this.clear()
        this.audioPlayer.stop()
        this.animationPlayState = 'paused'
      }
    },
    async updateSession() {
      const $event = {
        duration: this.duration,
        timePassed: this.timePassed
      }
      this.$emit('stats', $event)
      await this.sessionProgress([this, $event])
    },
    clear() {
      this.updateSession()
      clearInterval(this.intervalId)
      clearInterval(this.trackIntervalId)
      this.timePassed = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.relaxation-ball {
  @keyframes breathIn {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }

  @keyframes breathOut {
    from {
      transform: scale(1.3);
    }
    to {
      transform: scale(1);
    }
  }

  .image-wrap {
    width: 100%;
    max-width: 450px;
    margin: 0 auto 0;
    z-index: 10;

    @media (max-height: 710px) {
      margin: -6rem auto 0;
    }

    @media (max-height: 710px), (max-width: 959px) {
      margin: -1rem auto 0;
    }

    &.clickable {
      cursor: pointer;
    }

    &-helper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .ball {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      width: 64%;
      height: 64%;
      margin-top: -32%;
      margin-left: -32%;
      border-radius: 50%;
      color: #ffffff;
      transition: background-color 0.3s ease-in;
      animation-timing-function: linear;

      @media (max-width: 599px) {
        width: 70%;
        height: 70%;
        margin-top: -35%;
        margin-left: -35%;
      }

      &.breath-in {
        animation-name: breathIn;
      }

      &.breath-out {
        animation-name: breathOut;
      }

      &.hold-on {
        transform: scale(1.3);
      }

      &.hold-out {
        transform: scale(1);
      }

      &-button {
        width: 162px;
        height: 162px;

        @media (max-width: 599px) {
          width: 90px;
          height: 90px;
        }

        svg {
          width: 100%;
          height: auto;
        }
      }

      &-text {
        text-align: center;
        line-height: 1;
      }

      &-timer {
        font-weight: 700;
        font-size: 90px;

        @media (max-width: 599px) {
          font-size: 55px;
        }
      }
    }
  }

  .relaxation {
    &-timer {
      font-size: 48px;
      font-weight: 300;
      color: var(--v-neutralMedium-base);
      line-height: 1;

      @media (max-width: 959px) {
        font-size: 42px;
      }

      @media (max-width: 599px) {
        font-size: 28px;
      }
    }
  }

  &--dense {
    .image-wrap {
      width: 100%;
      max-width: 450px;
      margin: 0 auto 0;
      z-index: 10;

      @media(max-height: 710px) {
        margin: -6rem auto 0;
      }

      .ball {
        &-button {
          width: 80px;
          height: 80px;
        }

        &-text .display-1 {
          font-size: 24px !important;
        }

        &-timer {
          font-size: 50px;
        }
      }
    }

    .relaxation {
      &-timer {
        font-size: 28px;
        font-weight: 300;
        color: var(--v-neutralMedium-base);
        line-height: 1;
      }
    }
  }
}
</style>
