<template>
  <div
    id="dokbot"
    :class="[
      'dokbot-component mx-auto',
      { 'dokbot-component--in-phone-preview': inPhonePreview },
      { 'dokbot-component--in-modal-preview mx-auto': inModalPreview }
    ]"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from '@/plugins/moment'

export default {
  name: 'DokbotComponent',
  props: {
    inputs: {
      type: Object,
      required: true
    },
    workflowId: {
      type: String,
      required: true
    },
    timeId: {
      type: String,
      required: false,
      default: ''
    },
    scope: {
      type: Object,
      required: false,
      default: () => {}
    },
    isPreviewMode: {
      type: Boolean,
      default: false
    },
    inPhonePreview: {
      type: Boolean,
      default: false
    },
    inModalPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newTimeId: null,
      epoch: null,
      passed: null,
      stepsCount: null,
      notificationId: null,
      config: {
        //? https://dokbot.io/developers/
        // 'onReady,onStart,onCompletion,onStateChange,onEvent,onError,onRedirect',
        onReady: async data => {
          // console.log('onReady', data)
          this.stepsCount = data.steps.length
          await this.sessionStart(this)
        },
        onStateChange: async data => {
          // console.log('onStateChange', data)
          if (undefined !== data.step && null !== data.step) {
            if (null !== this.passed) {
              // console.log(this.passed, 'onStateChange', data)
              await this.sessionProgress([this, { progress: 1 }])
              /*await this.sessionProgress([
                this,
                {
                  stepsPassed: ++this.passed,
                  stepsCount: this.stepsCount
                }
              ])*/
            }

            if (null === this.passed) {
              this.passed = 0
            }
          }
        },
        onCompletion: async data => {
          await this.sessionProgress([
            this,
            {
              dokbotData: data,
              type: 'dokbot',
              progress: 100,
              duration: this.durarion()
            }
          ])
        }
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('clients', ['haveChosenClient'])
  },
  async mounted() {
    this.notificationId = this.timeId
    await this.initDokbot()

    /**
     * TODO:
     * Error: Document is ready and element #dokbot does not exist
     * (occurs right after login)
     */
    window.Dokbot.RuntimeComponent({
      workflowId: this.workflowId,
      params: this.inputs,
      consultationId: '+',
      ...this.config
    }).render('#dokbot')
  },
  methods: {
    ...mapActions('schedules', ['schedule', 'sessionStart', 'sessionProgress']),
    ...mapMutations({
      updateSchedule: 'schedules/updateItem'
    }),
    triggerStarted(name, data) {
      if (name === 'onStart') {
        this.epoch = 'started'
      }

      if (name === 'onStateChange' && this.epoch === 'ready') {
        this.onEvent('onStarted', data)
      }

      if (
        this.epoch === 'started' &&
        name === 'onStateChange' &&
        undefined !== data.step
      ) {
        this.epoch = 'ready'
      }
    },
    durarion() {
      return moment().diff(this.startedAt, 'second')
    },
    initDokbot() {
      return new Promise(resolve => {
        let script = document.getElementById('dokbot-bootstrap')

        if (script) {
          return resolve()
        }

        script = document.createElement('script')

        script.setAttribute('id', 'dokbot-bootstrap')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://resources.dokbot.io/bootstrap/bootstrap.min.js'
        script.onload = () => resolve()

        document.head.appendChild(script)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dokbot-component {

  // Standard non-preview styling
  max-width: 584px;
  height: 80vh;
  box-shadow: 0px 0px 1px 1px rgb(245, 246, 250);
  @media (min-width: 600px) {
    margin-top: -300px;
  }

  // Mobile phone preview
  &--in-phone-preview {
    margin-top: 0;
    @media (min-width: 380px) {
      height: 452px;
    }
    @media (max-height: 860px) {
      height: 464px;
    }
    @media (max-height: 740px) {
      height: 397px;
    }
  }

  // Modal preview
  &--in-modal-preview {
    margin-top: 0;
    @media (min-width: 380px) {
      height: 550px;
    }
  }
}
</style>
