<template>
  <v-form @submit.prevent="next">
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>

      <v-col class="col-12 pa-0">
        <div class="wrapper">
          <div
            ref="list"
            v-resize="onResize"
            class="list"
          >
            <div
              ref="listHelper"
              class="list-helper"
              :style="listHelperOffset"
            >
              <div
                v-for="item in items"
                :key="item.id"
                class="list-item"
              >
                <v-card
                  :class="[
                    'd-inline-flex choice-item text-center',
                    { selected: value.id === item.id }
                  ]"
                  @click="click(item)"
                >
                  <div class="choice-item-title">
                    {{ item.title }}
                  </div>
                  <div
                    v-if="item.text"
                    class="choice-item-text"
                  >
                    {{ item.text }}
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'Choice2',
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    callback: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: {},
      listHelperOffset: {}
    }
  },
  mounted() {
    this.value = this.scope[this.property] || {}
  },
  methods: {
    onResize() {
      const diff =
        this.$refs.list.offsetWidth - this.$refs.listHelper.offsetWidth
      const offset = diff > 0 ? diff / 2 : 0

      this.listHelperOffset = { left: `${offset}px` }
    },
    async click(item) {
      await this.$emit('track', {
        event: `adhere.ly:${this.property}:${item.title}`,
        item: {
          id: item.id,
          title: item.title
        }
      })
      this.value = item
      this.next()
    },
    async next() {
      await this.$emit('go-to-next-step', this.callback(this.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 514px;
  margin: 0 auto;
}

.list {
  width: calc(100% + 20px);
  margin-left: -10px;

  @media (max-width: 599px) {
    width: calc(100% + 8px);
    margin-left: -4px;
    &-helper {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &-item {
      display: inline;
      vertical-align: top;
    }
  }

  &-helper {
    position: relative;
    display: inline;
  }

  &-item {
    display: inline;
    vertical-align: top;
  }
}

.choice-item {
  position: relative;
  width: 158px;
  height: 63px;
  margin: 0.625rem;

  @media (max-width: 599px) {
    width: 150px;
  }

  @media (max-width: 480px) {
    width: calc(100% - 16px);
    padding: 0 15px;
  }
}
</style>
