<template>
  <div
    :class="[
      'd-flex relaxation-select justify-center',
      { 'relaxation-select--dense': dense }
    ]"
  >
    <v-card
      v-for="item in items"
      :key="item.value"
      :class="[
        'd-flex align-center justify-center relaxation-select-item',
        { active: isSelected === item.value }
      ]"
      @click="$emit('action-selected', item.value)"
    >
      <img
        :src="item.img"
        :alt="item.title"
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'RelaxationSelect',
  props: {
    isSelected: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [
        {
          title: 'balloon',
          value: 'relaxation-show',
          img: require('@/assets/img/balloon-tb.svg')
        },
        {
          title: 'ball',
          value: 'relaxation2-show',
          img: require('@/assets/img/ball-tb.svg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.relaxation-select {
  margin: 0 auto;

  @media (max-width: 599px) {
    width: 100%;
    margin-bottom: 15px;
  }

  &-item {
    width: 110px;
    height: 110px;
    margin: 0 12px;
    padding: 20px;
    border: 1px solid #d1d9de;
    cursor: pointer;

    @media (max-width: 599px) {
      width: 60px;
      height: 60px;
      padding: 10px;
    }

    &.active {
      border-color: var(--v-primary-base);
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &--dense {
    margin: 0;

    .relaxation-select {
      &-item {
        width: 50px;
        height: 50px;
        margin: 8px 8px 0;
        padding: 8px;

        @media (max-width: 379px) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
