<template>
  <v-form
    ref="form"
    v-model="valid"
    :autocomplete="autocomplete ? 'on' : 'off'"
    @submit.prevent="next"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          class="h1"
          v-html="title"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="value"
          :type="type"
          :placeholder="placeholder"
          solo
          height="var(--input-height)"
          autofocus
          :rules="rules"
          :autocomplete="autocomplete"
          required
          :validate-on-blur="!showErrorMessage"
          :maxlength="maxLength"
          :counter="counter"
          @input="change"
        />
      </v-col>

      <actions
        :valid="valid"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </v-row>
  </v-form>
</template>

<script>
import Actions from '@/kit/components/Actions'
import { mapActions } from 'vuex'

export default {
  components: { Actions },
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    },
    track: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: Number,
      default: 255
    },
    counter: {
      type: Number,
      default: undefined
    },
    autofocus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      valid: true,
      showErrorMessage: false,
      value: ''
    }
  },
  mounted() {
    this.value = this.scope[this.property] || ''

    this.$nextTick(() => {
      this.valid = this.$refs.form.validate()
    })
  },
  beforeDestroy() {
    this.resetUXState()
  },
  methods: {
    ...mapActions({
      resetUXState: 'ux/resetUXState'
    }),
    change() {
      this.valid = this.$refs.form.validate()
    },
    async next() {
      await this.$emit('track', {
        event: `adhere.ly:${this.property}:filled`,
        value: this.track ? this.value : undefined
      })

      this.showErrorMessage = true

      if (this.$refs.form.validate()) {
        this.$emit('go-to-next-step', { [this.property]: this.value })
        this.value = ''
      }
    }
  }
}
</script>
