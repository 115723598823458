import moment from '@/plugins/moment'
import { days } from '@/kit/helpers/datetime'

export default activity => {
  if (activity.date) {
    return { date: activity.date, time: activity.time }
  }
  const notificationConfig = activity.notificationConfig

  let date = []
  const time = []
  const isDaily = true

  let hourStart
  let hourEnd
  let hourRandom
  let minutesRandom
  let dayRandom
  let dateCreated

  const relativeNow = notificationConfig.relativeNow || 72
  switch (notificationConfig.type) {
    case 'once':
      notificationConfig.times.forEach(item => {
        time.push(
          moment(item, 'HH:mm')
            .utc()
            .format('HH:mm')
        )
      })

      date.push(
        moment()
          .add(relativeNow, 'hour')
          .format('YYYY-MM-DD')
      )

      break

    case 'random':
      hourStart = +notificationConfig.from.split(':')[0]
      hourEnd = +notificationConfig.to.split(':')[0]

      hourRandom =
        Math.floor(Math.random() * (hourEnd - hourStart + 1)) + hourStart
      minutesRandom = Math.floor(Math.random() * 60)
      dayRandom = Math.floor(Math.random() * 6) + 1

      dateCreated = moment()
        .hour(hourRandom)
        .minute(minutesRandom)
        .add(dayRandom, 'day')

      time.push(dateCreated.utc().format('HH:mm'))
      date.push(dateCreated.format('YYYY-MM-DD'))
      break

    case 'daily':
      notificationConfig.times.forEach(item => {
        time.push(
          moment(item, 'HH:mm')
            .utc()
            .format('HH:mm')
        )
      })
      date = days(isDaily)
      break

    default:
  }

  return { date, time }
}
