import ControlAutocomplete from '@/kit/controls/ControlAutocomplete'
import ControlInputText from '@/kit/controls/InputText'
import UserData from '@/store/authentication/getters'

export default (scope, params) => {
  return {
    name: 'name',
    controlComponent: scope.isUserLoggedIn
      ? ControlAutocomplete
      : ControlInputText,
    controlProps: {
      scope,
      params,
      property: 'name',
      autocomplete: '',
      background: params.background || 'secondary',
      title: params.title ? params.title : 'What is your client’s first name?',
      placeholder: scope.isUserLoggedIn
        ? 'Type client’s name to search'
        : 'e.g. Brian',
      rules: [
        v => {
          const name = typeof v === 'object' ? v.name : v
          return !!name || 'Name is required'
        }
        // v => v.length <= 50 || 'Max 50 characters'
      ],
      maxLength: 50,
      privacy: true
    }
  }
}
