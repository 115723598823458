<template>
  <v-form 
    class="relaxation"
    @submit.prevent="next"
  >
    <!-- isPreviewMode = true, where there's an iPhone mockup -->
    <template v-if="isPreviewMode">
      <preview-relaxation-component>
        <template
          v-if="$vuetify.theme.options.logo"
          v-slot:logo
        >
          <img
            :src="$vuetify.theme.options.logo"
            alt="Adhere"
          >
        </template>
        <template v-slot:body>
          <template v-if="isSelected === 'video-show'">
            <video-player
              v-if="areSourcesLoaded"
              :time-id="notificationId"
              :session="session"
              :duration="params.duration"
              :options="videoOptions"
              dense
              @stats="$emit('stats', $event)"
            />
          </template>
        </template>
      </preview-relaxation-component>
      <actions
        v-if="showPrevButton"
        wrapper-class="confirm-absolute justify-center justify-sm-space-between align-end flex-wrap"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </template>

    <!-- isPreviewMode = false, where there's no iPhone mockup -->
    <template v-else>
      <template v-if="isSelected === 'video-show'">
        <video-player
          v-if="areSourcesLoaded"
          :time-id="notificationId"
          :session="session"
          :duration="params.duration"
          :options="videoOptions"
          dense
          @stats="$emit('stats', $event)"
        />
      </template>

      <!-- Remind button -->
      <actions
        v-if="showPrevButton"
        wrapper-class="confirm-absolute justify-center justify-sm-space-between align-end flex-wrap"
        :label="params.nextBtnLabel"
        :icon="params.nextBtnLabel ? null : undefined"
      />
    </template>
  </v-form>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import format from 'string-template'
import { mapActions } from 'vuex'
import Actions from '@/kit/components/Actions'
// import RelaxationRollBreathing from '@/kit/components/relaxation/RelaxationRollBreathing'
import VideoPlayer from '@/kit/components/VideoPlayer'
import PreviewRelaxationComponent from '@/components/PreviewRelaxationComponent'

export default {
  name: 'Video',
  components: {
    Actions,
    // RelaxationRollBreathing,
    VideoPlayer,
    PreviewRelaxationComponent
  },
  props: {
    scope: {
      type: Object,
      required: false
    },
    params: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: false
    },
    showPrevButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mdiPlus,
      notificationId: null,
      session: null,
      isSelected: this.params.selected || 'video-show',
    }
  },
  computed: {
    isPreviewMode() {
      this.params.previewMode ? this.setUXPreviewMode(true) : this.setUXPreviewMode(false)
      return this.params.previewMode
    },
    areSourcesLoaded() {
      return !!this.videoOptions.sources
    },
    videoOptions() {
      return {
        controls: false,
        preload: 'metadata',
        fullscreenToggle: false,
        playsinline: true,
        // height: 'auto',
        border: 'none',
        sources: this.params?.videoFiles?.map(vf => {
          return {
            ...vf,
            src: '/media/' + vf.src,
          }
        })
      }
    }
  },
  async mounted() {
    this.setUXPreviewMode(this.params.previewMode)
    await this.sessionStart(this)
  },
  beforeDestroy() {
    this.resetUXState()
  },
  methods: {
    ...mapActions({
      sessionStart: 'schedules/sessionStart',
      setUXPreviewMode: 'ux/setUXPreviewMode',
      resetUXState: 'ux/resetUXState'
    }),
    async next() {
      const link = format(`{baseUrl}/relaxation/${this.isSelected}/{id}`, {
        id: '{id}',
        baseUrl: process.env.VUE_APP_BASE_URL
        // Dev - can use your local address here if needed for mobile tests
        // baseUrl: 'https://192.168.1.8:8080'
      })

      this.$emit('track', {
        event: 'adhere.ly:relaxation:selected',
        link
      })

      this.$emit('go-to-next-step', {
        link,
        duration: this.params.duration
      })
    }
  }
}
</script>
