<template>
  <v-row
    :class="[
      'exercise-video no-gutters',
      { 'exercise-video--dense': dense }
    ]"
  >
    <v-col cols="12">
      <div class="d-flex justify-center">
        <div
          :class="['video-wrap', { clickable: !isVideoFinished }]"
          @click="togglePlay"
        >
          <div class="video-wrap-helper">
            <!-- Container -->
            <div 
              class="video-container"
            >
              <!-- Video -->
              <video 
                ref="videoElement" 
                class="video-js"
              />
              
              <!-- Control button -->
              <div
                v-if="!isVideoFinished"
                class="control-button"
              >
                <icon-play-filled v-if="!isVideoPlaying" />
                <icon-pause-filled v-else />
              </div>

              <!-- Timer -->
              <!-- <div class="exercise-timer text-center">
                {{ clockString }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="col-12 exercise-timer text-center">
      {{ clockString }}
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import IconPlayFilled from '@/components/icons/IconPlayFilled'
import IconPauseFilled from '@/components/icons/IconPauseFilled'
import videojs from 'video.js'
import VideoPlayer from '@/kit/helpers/VideoPlayer'

export default {
  name: 'VideoPlayer',
  components: { IconPlayFilled, IconPauseFilled },
  props: {
    duration: {
      type: Number,
      required: true
    },
    timeId: {
      type: String,
      required: false,
      default: () => null
    },
    session: {
      type: Object,
      required: false,
      default: () => null
    },
    dense: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Don't seem to be used, but might be referenced by parent:
      completeOn: 70,
      sessionRef: null,
      notificationId: this.timeId,
      //
      videoPlayer: null,
      isVideoPlaying: false,
      isVideoFinished: false,
      trackIntervalId: null,
      clockIntervalId: null,
      clockString: 'Loading'
    }
  },
  beforeDestroy() {
    // this.videoPlayer.destroy()
    this.clear()
  },
  mounted() {
    this.videoPlayer = new VideoPlayer(videojs(this.$refs.videoElement, this.options))

    this.videoPlayer.once('ended', () => {
      this.isVideoFinished = true
      this.clear()
    })

    this.clockIntervalId = setInterval(() => {
      this.clockString = this.videoPlayer.getClockString()
    }, 100)
  },
  methods: {
    ...mapActions('schedules', ['sessionProgress']),
    togglePlay() {
      // cancel event if the player is stopped
      if (this.isVideoFinished) return false
      if (this.videoPlayer.getTimeRemaining() < 1) return false

      // Toggle
      this.isVideoPlaying = !this.isVideoPlaying

      if (this.isVideoPlaying) {
        // Video has just started playing
        this.videoPlayer.play()

        if (!this.trackIntervalId) {
          this.updateSession()
          this.trackIntervalId = setInterval(this.updateSession, 3000)
        }
      } else {
        // Video has stopped playing
        this.videoPlayer.stop()

        clearInterval(this.trackIntervalId)
        this.trackIntervalId = null
      }
    },
    async updateSession() {
      const $event = {
        duration: this.videoPlayer.getDuration(),
        timePassed: this.videoPlayer.getTimePassed()
      }
      this.$emit('stats', $event)
      await this.sessionProgress([this, $event])
    },
    clear() {
      this.updateSession()
      clearInterval(this.clockIntervalId)
      clearInterval(this.trackIntervalId)
    },
  }
}
</script>

<style lang="scss" scoped>

.exercise-video {
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;

  .video-wrap {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    &.clickable {
      cursor: pointer;
    }

    &-helper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .video-container {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      max-height: auto;
      overflow: hidden;
      padding-bottom: 210px;
    
      video {
        display: block;
        width: 100%;
        height: auto;
        top: -2%;
        padding: 10px;
      }
    }
  }

  .video-js {
    background-color: transparent !important;
    order: 0;
    align-self: center;
  }

  .control-button {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 162px;
    height: 162px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      height: auto;
    }
  }

  .exercise {
    &-timer {
      font-size: 48px;
      font-weight: 300;
      color: var(--v-neutralMedium-base);
      line-height: 1;
      align-self: center;

      @media (max-width: 959px) {
        font-size: 42px;
      }

      @media (max-width: 599px) {
        font-size: 28px;
      }
    }
  }

  &--dense {
    .control-button {
      width: 80px;
      height: 80px;
    }

    .exercise {
      &-timer {
        margin-top: 75px;
        font-size: 28px;
        font-weight: 300;
        color: var(--v-neutralMedium-base);
        line-height: 1;
      }
    }
  }
}
</style>
