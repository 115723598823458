<template>
  <v-text-field
    v-model="search"
    class="v-text-field-generic"
    flat
    solo
    :placeholder="parentPage === 'assess' ? 'GAD-7' : 'deep breathing'"
    single-line
    hide-details
    @input="searchExercise"
  />
</template>

<script>
export default {
  props: ['parentPage'],
  data() {
    return {
      search: ''
    }
  },
  watch: {
    parentPage: {
      handler() {
        this.search = ''
        this.$emit('search-updated', this.search)
      }
    }
  },
  methods: {
    searchExercise(value) {
      this.search = value
      this.$emit('search-updated', value)
    }
  }
}
</script>

<style scoped>
.v-text-field-generic {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  max-height: 2rem;
  max-width: 12rem;
}

>>>.v-input__control {
  min-height: unset !important;
}
>>>.v-input__slot, >>>.v-input__slot input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family-primary);
  background: none !important;
}
</style>