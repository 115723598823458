import format from 'string-template'
import ControlChoice from '@/kit/controls/Choice2'
import channels from '@/kit/dictionaries/channels'

export default (scope, params) => {
  return {
    name: 'channel',
    controlComponent: ControlChoice,
    controlProps: {
      scope,
      params,
      property: 'channel',
      title: format(params.title, scope),
      items: channels(),
      callback: value => ({
        channel: value,
        channelName: value.title.toLowerCase()
      })
    }
  }
}
