<template>
  <div>
    <h1
      v-if="!params.sendAt"
      class="h1 text-center"
    >
      <template v-if="random">
        On a
        <a @click.prevent="goToStep(goToDateStep, 'summary')">random day</a>
        and
        <a @click.prevent="goToStep(goToTimeStep, 'summary')">time</a> this
        week<br>
        <template v-if="clientName">
          <a @click.prevent="goToStep('name', 'summary')">
            <span>{{ clientName }}</span>
          </a>
        </template>
        <template v-else>
          your client
        </template>
        will receive this
        <a @click.prevent="goToStep('channel', 'summary')">
          <span v-html="channelName" /> </a>:
      </template>

      <template v-if="daily">
        <a @click.prevent="goToStep(goToDateStep, 'summary')">Every day</a> at
        <a @click.prevent="goToStep(goToTimeStep, 'summary')">
          <span v-html="timesFormatted" />
        </a>
        {{ formattedDailyTitle }}
        <template v-if="clientName">
          <a @click.prevent="goToStep('name', 'summary')">
            <span>{{ clientName }}</span>
          </a>
        </template>
        <template v-else>
          your client
        </template>
        will receive this
        <a @click.prevent="goToStep('channel', 'summary')">
          <span v-html="channelName" /> </a>:
      </template>

      <template v-if="appointment">
        {{ formattedAppointmentTitle }}
        <a @click.prevent="goToStep(goToDateStep, 'summary')">
          <span v-html="datesFormatted" />
        </a>
        at
        <a @click.prevent="goToStep(goToTimeStep, 'summary')">
          <span v-html="timesFormatted" />
        </a>
        <br>
        <template v-if="clientName">
          <a @click.prevent="goToStep('name', 'summary')">
            <span>{{ clientName }}</span>
          </a>
        </template>
        <template v-else>
          your {{ clientPhrase }}
        </template>
        will receive this
        <a @click.prevent="goToStep('channel', 'summary')">
          <span
            v-html="channelName"
          /></a>
        the day before:
      </template>
      <template v-if="once">
        {{ formattedOnceTitle }}
        <a @click.prevent="goToStep(goToDateStep, 'summary')">
          <span v-html="datesFormatted" />
        </a>
        at
        <a @click.prevent="goToStep(goToTimeStep, 'summary')">
          <span v-html="timesFormatted" />
        </a>
        <br>
        <template v-if="clientName">
          <a @click.prevent="goToStep('name', 'summary')">
            <span>{{ clientName }}</span>
          </a>
        </template>
        <template v-else>
          your {{ clientPhrase }}
        </template>
        will receive this
        <a @click.prevent="goToStep('channel', 'summary')">
          <span v-html="channelName" /></a>:
      </template>
    </h1>

    <h1
      v-if="params.sendAt"
      class="h1 text-center"
    >
      You will receive this {{ channelName }} in a moment:
    </h1>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import channels from '@/kit/dictionaries/channels'
import moment from '@/plugins/moment'

export default {
  name: 'SummaryTitle',
  props: {
    scope: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('authentication', ['clientPhrase', 'client']),
    ...mapGetters('schedules', ['recurrence']),
    ...mapGetters('app', ['isLocked']),
    goToDateStep() {
      if (this.params.aliases && this.params.aliases.date) {
        return this.params.aliases.date
      }

      return 'date'
    },
    goToTimeStep() {
      if (this.params.aliases && this.params.aliases.time) {
        return this.params.aliases.time
      }

      return 'time'
    },
    channel() {
      //console.log('this.scope.channel.id', this.scope.channel)
      if (this.scope.channel.id) {
        return channels().find(channel => channel.id === this.scope.channel.id)
      } else {
        return channels().find(channel => channel.key === this.scope.channel)
      }
    },
    channelName() {
      if (this.channel) {
        return this.channel.title.toLowerCase()
      }

      return ''
    },
    formattedAppointmentTitle() {
      if (this.recurrence.isRecurring) {
        return 'For this planned ' + this.recurrence.frequency.toLowerCase()
          + ' appointment starting '
      }
      return 'For this planned appointment on'
    },
    formattedDailyTitle() {
      if (this.recurrence.isRecurring) {
        return 'For the next week, and recurring ' + this.recurrence.frequency.toLowerCase()
          + ', '
      }
      return 'For the next week,'
    },  
    formattedOnceTitle() {
      if (this.recurrence.isRecurring) {
        return 'Repeating ' + this.recurrence.frequency.toLowerCase()
          + ', starting this '
      }
      return 'This'
    },
    sendDateTimes() {
      const datetimes = []
      this.dates.forEach(itemDate => {
        this.times.forEach(itemTime => {
          const dateCreated = moment.utc(
            itemDate + ' ' + itemTime,
            'YYYY-MM-DD HH:mm'
          )

          datetimes.push(dateCreated)
        })
      })

      return datetimes

      // return this.daily
      //   ? datetimes
      //   : datetimes.map(datetime =>
      //       moment(datetime.local().format('YYYY-MM-DD HH:mm')).isAfter(
      //         moment().format('YYYY-MM-DD HH:mm')
      //       )
      //         ? datetime
      //         : moment().utc()
      //     )
    },
    datesFormatted() {
      const dates = this.sendDateTimes.map(datetime => {
        return datetime.format('YYYY-MM-DD')
      })

      const finalDates = dates.length > 5 
        ? this.datesFormat([...new Set([dates[0], dates[dates.length - 1]])], true)
        : this.datesFormat([...new Set(dates)], false)

      return finalDates
    },
    timesFormatted() {
      const times = this.sendDateTimes.map(datetime => {
        return datetime.format('HH:mm')
      })

      return this.timesFormat([...new Set(times)])
    },
    dates() {
      return [...(this.scope.date || [])]
    },
    times() {
      return [...(this.scope.time || [])]
    },
    clientName() {
      if (true === this.scope?.caregiver) {
        return this.scope.caregiverName || this.scope.name
      }

      return this.scope.name
    },
    type() {
      if (this.scope.step === 'summary') {
        this.updateReminder(this.params.reminder)
      }
      return this.scope.activity.notificationConfig.type
    },
    random() {
      return this.type === 'random'
    },
    daily() {
      return this.type === 'daily'
    },
    once() {
      return this.type === 'once'
    },
    appointment() {
      return this.type === 'appointment'
    }
  },
  methods: {
    ...mapMutations({
      updateSchedule: 'schedules/updateItem',
      updateEditType: 'schedules/updateEditType',
      updateReminder: 'schedules/updateReminder'
    }),
    goToStep(step, backToStep) {
      if (this.isLocked && step === 'name') {
        // //console.log('Cannot go to name step (app is in locked state)')
        return
      }
      this.$emit('go-to-step', { step, backToStep })
      this.updateEditType(step)
    }
  }
}
</script>
