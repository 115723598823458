<template>
  <v-col
    v-if="show"
    :class="['col-12 d-flex', wrapperClass]"
  >
    <slot />

    <v-btn
      v-if="label"
      type="submit"
      x-large
      color="primary"
      data-cy="next"
      :disabled="!valid || disabled"
    >
      <v-icon v-if="appendIcon && icon">
        {{ icon }}
      </v-icon>
      {{ label }}
      <v-icon v-if="!appendIcon && icon">
        {{ icon }}
      </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapGetters } from 'vuex';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    },
    valid: {
      type: Boolean,
      default: true
    },
    label: {
      type: [String, Boolean],
      default: 'Next'
    },
    icon: {
      type: String,
      default: mdiChevronRight
    },
    appendIcon: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      type: String,
      default: 'justify-end'
    }
  },
  computed: {
    ...mapGetters({
      activityTitle: 'ux/activityTitle',
      isPreviewMode: 'ux/isPreviewMode',
      activityType: 'ux/activityType',
      reminderType: 'ux/reminderType'
    })
  }
}
</script>

<style lang="scss" scoped>

.v-btn:not(.v-btn--round).v-size--x-large {
  height: var(--btn-size-x-large);
  position: relative;
  bottom: 0;
  right: 0;
  margin: 15px 35px 105px;

  @media(min-width: 1139px) {
    height: var(--btn-size-x-large);
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 15px 35px 105px;
  }

  // @media (max-height: 860px) {
  //   margin: 15px 35px 155px;
  // }

  @media (max-height: 740px) {
    margin: 15px 35px 105px;
  }

  /* Commented out so the action button stays above the help button */
  // @media (max-height: 825px) {
  //   margin: 15px 35px 35px;
  // }

  @media (max-width: 959px) {
    margin: 15px 35px 105px;
  }

  @media (max-width: 419px) {
    margin: 15px 15px 115px;
  }
}
</style>
